
import React from 'react'

import ElasticSearchService from "../../services/elasticSearch.service";
import StorageService from "../../services/storage.service";
import { Container, Row, Col, Breadcrumb, Form, Button, Table } from 'react-bootstrap'
import AuthService from "../../services/auth.service";
import FileSaver from 'file-saver';
import TombstoneMapDetailElement from "../public/details/tombstoneMapDetailElement2";
import { handleStorage } from '../../helpers/jfb'
import * as Constants from "../../components/constants";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

class ListGenerator extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			choices: {},
			choiceKeys: [],
			cementries: [],
			isLoading: true,
			errors: null,
			successful: false,
			message: "",
			selectedCementry: "0",
			currentCementry: {},
			currentUser: undefined,

			selectedField: { "label": "Felder zur Auswahl", "id": "0" },
			selectedFields: [],
			availableFields: [
				{ "label": "Felder zur Auswahl", "id": "0" },
				{ "label": "Beruf", "id": "1" },
				{ "label": "Begräbnisdatum", "id": "2" },
				{ "label": "Bild", "id": "3" },
				{ "label": "Grabstein-ID", "id": "4" },
				{ "label": "Grabsteintyp", "id": "5" },
				{ "label": "Geburtsdatum", "id": "6" },
				{ "label": "Geburtsort", "id": "7" },
				{ "label": "Hersteller", "id": "8" },
				{ "label": "Konstruktive Schäden", "id": "9" },
				{ "label": "Konservierung/Maßnahmeplanung", "id": "10" },
				{ "label": "Name", "id": "11" },
				{ "label": "Material", "id": "12" },
				{ "label": "Sterbedatum", "id": "13" },
				{ "label": "Wohnort", "id": "14" },
				{ "label": "Schadensbild", "id": "15" },
				{ "label": "Schäden im Kontext von Schändungen", "id": "16" },
				{ "label": "Schmuck", "id": "17" },
				{ "label": "Sterbeort", "id": "18" },
				{ "label": "Stellung", "id": "19" },
				{ "label": "Stil", "id": "20" },
				{ "label": "Stilmittel", "id": "21" },
				{ "label": "Symbol", "id": "22" },
				{ "label": "Verwitterungsschäden", "id": "23" },
				{ "label": "Zitate", "id": "24" },
				{ "label": "Zustand Grabstein", "id": "25" },
				{ "label": "Zustand Inschrift", "id": "26" },
				{ "label": "Zustand/Sonstiges", "id": "27" },
			],
			grabsteinnummerValue: "",

			headerList: [],
			contentList: {},
			tombstones: [],
			allTombstones: [],
			isAllTombstonesLoading: true,
			isListCreated: false,
			listNameValue: "",
			reports: [],
			isReportLoading: true,
			googleMapConsent: false,
		};


		this.handleCementryChange = this.handleCementryChange.bind(this);
		this.handleReportChange = this.handleReportChange.bind(this);

	}

	createLink(data) {
		let link = data.substring(8);

		return link;
	}

	createLinkPerson(data) {
		let link = data.split(":");

		return link;
	}



	sortColumn(field, column) {

		let sortByTombstoneId = this.state.sortByTombstoneId;
		let sortByDate = this.state.sortByDate;
		let list = this.state.contentList;
		console.log(this.state.contentList);
		console.log(column);



		if (sortByTombstoneId === "0") {
			list.sort((a, b) => b[column].localeCompare(a[column]));
			sortByTombstoneId = "1";
		}
		else {
			list.sort((a, b) => a[column].localeCompare(b[column]));
			sortByTombstoneId = "0";
		}


		this.setState({

			contentList: list,
			sortByTombstoneId: sortByTombstoneId,
			sortByDate: sortByDate
		});
	}



	createCsvList(e) {

		this.setState({
			message: ""
		});

		let selectedFields = this.state.selectedFields;

		ElasticSearchService.createCsvList(selectedFields).then(
			response => {

				let fileName = "csvReport.csv"

				FileSaver.saveAs(new Blob([response.data], { type: "text/plain;charset=utf-8" }), fileName);
			},
			error => {
				this.setState({
					error: (error.response && error.response.data) || error.message || error.toString(),
					successful: false,
					message: error.toString()
				});
			}
		);
	}

	saveList(e) {
		let selectedFields = this.state.selectedFields;
		let listName = this.state.listNameValue;
		let user = AuthService.getCurrentUser();

		if (selectedFields.length === 0) {
			this.setState({
				message: "Es wurden keine Felder für die Liste ausgewählt",
			});
			return;
		}

		ElasticSearchService.saveList(selectedFields, listName, user.username).then(
			response => {

				this.setState({
					name: response.data,
					message: "Liste wurde gespeichert.",
					successful: true
				});
			},
			error => {
				this.setState({
					error: (error.response && error.response.data) || error.message || error.toString() || error.data.message,
					successful: false,
					message: error.toString()
				});
			}

		)

	}

	createList(e) {
		let selectedFields = this.state.selectedFields;

		console.log(selectedFields)
		if (selectedFields.length === 0) {
			this.setState({
				message: "Es wurden keine Felder für die Liste ausgewählt",
			});
			return;
		}

		ElasticSearchService.createList(selectedFields).then(
			response => {

				this.setState({
					headerList: response.data.headerList,
					contentList: response.data.contentList,
					tombstones: response.data.tombstones,
					statistics: response.data.statistics,
					isListCreated: true,
					message: "",
					successful: true,
					sortByTombstoneId: "0",
					sortByDate: "0"
				});
			},
			error => {

				this.setState({
					error: (error.response && error.response.data) || error.message || error.toString() || error.data.message,
					successful: false,
					message: error.toString()
				});
			}

		)



		ElasticSearchService.loadTombstones(this.state.currentCementry.id).then(
			response => {

				this.setState({
					allTombstones: response.data.tombstones,
					isAllTombstonesLoading: false,
				});
			},
			error => {
				this.setState({
					error: (error.response && error.response.data) || error.message || error.toString() || error.data.message,
					successful: false,
					message: error.toString()
				});
			}
		);


	}

	handleOperatorChange(e, id) {

		let selectedFields = this.state.selectedFields;
		let selectedField = selectedFields[id];
		selectedField.operator = e.target.value;
		selectedField.value = "";
		selectedFields[id] = selectedField
		this.setState({
			selectedFields: selectedFields,
			message: "",
		});
		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));

	}

	changeValue(e, id) {
		let selectedFields = this.state.selectedFields;
		let selectedField = selectedFields[id];
		selectedField.value = e.target.value;
		selectedFields[id] = selectedField
		this.setState({
			selectedFields: selectedFields,
			message: "",
		});
		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));
	}

	onAddField(e) {


		if (this.state.selectedField.id === "0") {
			this.setState({
				message: "Bitte wählen Sie zuerst ein Feld aus",
			});
			return;
		}

		let selectedField = this.state.selectedField
		let selectedFields = this.state.selectedFields;

		selectedFields.push({ name: selectedField.label, operator: "", value: "", cementry: this.state.selectedCementry })

		this.setState({
			selectedFields: selectedFields,
			selectedField: { "label": "Felder zur Auswahl", "id": "0" },
			message: "",
		});


		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));
	}

	onRemoveField(e, field) {


		let selectedFields = this.state.selectedFields;
		var index = selectedFields.indexOf(field);
		selectedFields.splice(index, 1);

		this.setState({
			selectedFields: selectedFields,
			selectedField: { "label": "Felder zur Auswahl", "id": "0" },
			message: "",
		});

		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));

	}

	handleFieldChange(e) {
		let id = e.id;
		console.log(e);
		let value = this.state.availableFields.find(entry => entry.id === id)

		this.setState({
			selectedField: value,
			message: "",
		});
	}

	handleFieldValueChanged(newValue, group, id) {
		console.log("da: " + newValue);

		let givenValue = newValue;

		let value = ""
		let selectedFields = this.state.selectedFields;
		let selectedValue = selectedFields[id];
		try {
			if (newValue === null) {
				newValue = { "label": "", "id": "-1" };
			}
			value = this.state.choices[group].find(entry => entry.id === newValue.id);
			selectedValue.value = value.label;
		} catch (error) {
			selectedValue.value = givenValue;
		}



		this.setState({
			selectedFields: selectedFields,
		});
		localStorage.setItem("selectedFields", JSON.stringify(selectedFields));
	}

	checkDisabled(fieldName) {
		let fields = this.state.availableFields;
	}

	handleCementryChange(e) {
		console.log(e);
		if (e !== null) {
			let id = e.id;

			this.setState({
				selectedCementry: id,
				result: {},
				query: "",
				message: "",
				isSearching: false,
				resultSize: 0,
				selectedFields: [],
				selectedReport: 0,
			});

			localStorage.setItem("cementery", JSON.stringify(id));

			let currentCementry = this.state.cementries.find(cementery => cementery.id === id);
			this.setState({
				currentCementry: currentCementry
			});
		}
	}

	handleReportChange(e) {

		let id = e.target.value;


		this.setState({
			selectedReport: id,
			message: "",
			selectedFields: [],
			headerList: [],
			contentList: [],
			tombstones: [],
			isListCreated: false,
		});


		let report = this.state.reports.find(report => report.id === id);
		let currentCementry = this.state.cementries.find(cementery => cementery.id === report.selectedFields[0].cementry);
		localStorage.setItem("cementery", JSON.stringify(currentCementry.id));
		localStorage.setItem("selectedFields", JSON.stringify(report.selectedFields));
		this.setState({
			currentCementry: currentCementry,
			selectedCementry: currentCementry.id,
			selectedReport: report.id,
			selectedFields: report.selectedFields,
		});


	}


	componentDidMount() {

		let googleMapConsent = handleStorage("googleMapConsent");
		this.setState({
			googleMapConsent: googleMapConsent,
		});

		const user = AuthService.getCurrentUser();
		if (user) {
			this.setState({
				currentUser: user,
			});
		}

		let selectedFields = StorageService.handleStorage("selectedFields");
		let cementeryId = "";
		if (selectedFields.length > 0) {
			cementeryId = selectedFields[0].cementry;
		}

		this.setState({
			selectedFields: selectedFields,
			selectedCementry: cementeryId,
		});

		let onlyPublished = user ? false : true;

		let callLoadCementries = onlyPublished ? ElasticSearchService.loadPublicCementries : ElasticSearchService.loadCementries;

		callLoadCementries().then(
			response => {

				let allCemeteries = response.data

				this.setState({

					allCemeteries: allCemeteries,
					isLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);


		ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
			response => {

				let cementeries = response.data
				let currentCementry = cementeries.find(cementery => cementery.id === cementeryId);
				this.setState({
					currentCementry: currentCementry,
					cementries: cementeries,
					isLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);



		ElasticSearchService.loadReports().then(
			response => {
				this.setState({
					reports: response.data,
					isReportLoading: false,
				});

			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);

		ElasticSearchService.loadChoicesForAutocomplete().then(
			response => {
				console.log(response.data);
				this.setState({
					choices: response.data,
					choiceKeys: Object.keys(response.data),
					isLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	getCementery(cementeries, cementeryId) {
		return cementeries.find(cementery => cementery.id === cementeryId);
	}


	getSelectedChoices(fieldValue) {
		let choices = this.state.choices;
		let values = choices[fieldValue];
		if (values !== undefined) {
			let element = { "label": "", "id": "-1" };
			let e1 = values[0];
			if (e1.label != element.label) {
				values.unshift(element);
			}
		}

		return values;
	}


	render() {
		const {
			cementries,
			isLoading,
			isReportLoading,
			selectedField,
			selectedFields,
			availableFields,
			isListCreated,
			headerList,
			contentList,
			statistics,
			listNameValue,
			reports,
			currentUser,
			isAllTombstonesLoading,
			tombstones,
			allTombstones,
			googleMapConsent,
			choices
		} = this.state;
		return (
			<Container>
				<Row>
					<Col>
						<Breadcrumb className="breadcrumb-style">
							<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
							<Breadcrumb.Item href="/cemeteries/">Historische Friedhöfe</Breadcrumb.Item>
							<Breadcrumb.Item active>Individuelle Auswertungen</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col><h3>Individuelle Auswertungen</h3></Col>
				</Row>
				<Row>
					<Col>
						Diesen Bereich können Sie nutzen, um auf einem Friedhof nach bestimmten Grabsteinen oder Personen zu suchen,
						die eine oder mehrere Bedingungen erfüllen.
						<br />
						Wählen Sie zunächst einen Friedhof aus. Anschließend wählen Sie aus der Dropdown-Liste ein Feld aus, das Sie
						im Ergebnis und für das Sie ggf. eine Bedingung definieren möchten.
						<br /><br />
						<strong>Beispiel:</strong> Sie möchten wissen, wie viele Grabsteine auf dem Friedhof Schopfloch vor dem 01.01.1600 exisitert haben.
						<br />
						<ul>
							<li>Wählen Sie das Feld "Grabstein" und klicken Sie auf "Feld Hinzufügen".</li>
							<li>Wählen Sie das Feld "Bild" und klicken Sie auf "Feld Hinzufügen"</li>
							<li>Wählen Sie das Feld "Sterbedatum" und klicken Sie auf "Feld Hinzufügen". Anschließend wählen Sie im Feld Operator "kleiner gleich" aus und geben als Wert das gewünschte Datum in der Form 1600-01-01 an.</li>
							<li>Mit einem Klick auf den Button "Liste erstellen", werden die ermittelten Grabsteine angezeigt und in der Karte dargestellt, wo sich die Steine ungefähr befinden.</li>
						</ul>

						Mit einem Klick auf den Link "Entfernen" können Sie ein hinzugefügten Feld aus der Auswertung entfernen.
					</Col>
				</Row>



				{!isLoading && !isReportLoading ? (
					<div>
						{currentUser && (
							<Row>
								<Col >
									<Form.Group as={Col} controlId="ReportChoice">
										<Form.Text className="text-muted">Bitte wählen Sie einen Report aus...</Form.Text>
										<Form.Control as="select" value={this.state.selectedReport} onChange={(e) => this.handleReportChange(e)}>
											<option key={0} value={0}>kein Report ausgewählt</option>
											{reports.map((report) => (<option key={report.id} value={report.id}>{report.id}</option>))}
										</Form.Control>
									</Form.Group>
								</Col>
							</Row>
						)}
						<Row>
							<Col >
								<Form.Group as={Col} controlId="CementryChoice">
									<Form.Text className="text-muted">Bitte wählen Sie einen Friedhof aus...</Form.Text>
									<Autocomplete
										value={this.getCementery(cementries, this.state.selectedCementry)}
										onChange={(event, newValue) => { this.handleCementryChange(newValue); }}
										disableClearable={true}
										id="combo-box-cementries"
										options={cementries}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group as={Col} controlId="FieldList">
									<Form.Text className="text-muted">Bitte wählen Sie ein Feld aus...</Form.Text>
									<Autocomplete
										value={selectedField}
										onChange={(e, newValue) => { this.handleFieldChange(newValue); }}
										disableClearable={true}
										id="combo-box-fields"
										options={availableFields}
										isOptionEqualToValue={(option, val) => option.id === val.id}
										renderInput={(params) => <TextField {...params} />}
									/>
								</Form.Group>
							</Col>
						</Row>
						{this.state.message && (
							<div className="form-group">
								<div className={this.state.successful ? "alert alert-success" : "alert alert-danger"} role="alert">
									{this.state.message}
								</div>
							</div>
						)}
						<Row>
							<Col >
								<Button variant="secondary" onClick={(e) => this.onAddField(e)} size="lg" block>Feld Hinzufügen</Button>
							</Col>
						</Row>
						{selectedFields.map((field, id) => (
							<Row key={id}>
								<Col>
									<Form.Group as={Col} controlId={field}>
										<Form.Label>Feld</Form.Label>
										<Form.Control key={id} disabled as="input" name={field.name} value={field.name} />
									</Form.Group>
								</Col>
								<Col xs={2}>
									<Form.Group as={Col} controlId={field}>
										<Form.Label>Operator</Form.Label>
										<Form.Control
											as="select" name={field.name + "-" + id + "-operator"}
											onChange={(e) => this.handleOperatorChange(e, id)}
											value={field.operator}
										>
											<option key="0" value="0">keine Auswahl getroffen</option>
											<option key="1" value="1">ist gleich</option>
											<option key="2" value="2">kleiner gleich</option>
											<option key="3" value="3">größer gleich</option>
											<option key="4" value="4">nicht leer</option>

										</Form.Control>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Col} >
										<Form.Label>Wert</Form.Label>
										<Autocomplete
											value={field.value}
											onChange={(e, newValue) => { this.handleFieldValueChanged(newValue, field.name, id); }}
											disableClearable={false}
											freeSolo={true}
											autoSelect={true}
											id={id + field.name}
											options={this.getSelectedChoices(field.name) === undefined ? [] : this.getSelectedChoices(field.name)}
											renderInput={(params) => <TextField {...params}

											/>
											}
										/>
									</Form.Group>
								</Col>
								<Col >
									<Form.Group as={Col} controlId={field}>
										<Button variant="Link" onClick={(e) => this.onRemoveField(e, field)}>Entfernen</Button>
									</Form.Group>
								</Col>
							</Row>
						))}
						<Row>
							<Col>
								<Button variant="secondary" onClick={(e) => this.createList(e)} size="lg" block>Liste erstellen</Button>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr />
							</Col>
						</Row>
						{isListCreated && !isAllTombstonesLoading ? (
							<div>


								<Row>
									<Col>
										{googleMapConsent ? (

											<TombstoneMapDetailElement cementery={this.getCementery(this.state.allCemeteries, this.state.selectedCementry)} tombstones={allTombstones} zoom={19} tombstoneMarker={tombstones} />
										) : (
											<div className="consent-info" >{Constants.consentText}<br />
												<strong>Den Button finden Sie unten auf dieser Seite!</strong></div>
										)}

									</Col>
								</Row>

								
								<Row>
									<Col>
										<strong>Statistik</strong><br/>Die folgenden Daten zeigen die Top 10 Egebnisse des Reports.
										<Tabs
											defaultActiveKey="profile"
											id="uncontrolled-tab-example"
											className="mb-2"
										>
											{statistics.map((agg, id) => (<Tab eventKey={agg.name} title={agg.name}>
												<Table striped bordered hover size="sm">
												<thead>
												           <tr>
												               <th>Zuordnung</th>
												               <th>Anzahl</th>
												           </tr>
												       </thead>
												<tbody>
													{agg.detailAggregation.map((a, id2) => (<tr><td>{a.key} </td> <td>{a.value}</td></tr>))}
												</tbody></Table>
											</Tab>))}

										</Tabs>

									</Col>
								</Row>
								<Row>
									<Col>Anzahl Ergebnisse in der Liste:{contentList.length}
								{console.log(contentList)}
									</Col>
								</Row>

								<Table striped bordered hover size="sm">
									<thead>
										<tr>
											{headerList.map((field, id) => (
												<th key={id} onClick={(e) => this.sortColumn(field, id)}>{field}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{contentList ? contentList.map((dataSet, id) => (
											<tr key={id}>
												{dataSet.map((data, id2) => (
													data.startsWith("http") ? <td key={id2}><img width={64} height={64} className="mr-3" src={data} alt="kein Bild vorhanden" /></td> :
														data.startsWith("linkgst:") ? <td key={id2}><a target="_blank" href={"/tombstoneDetails/" + this.state.selectedCementry + "/" + this.createLink(data)} >{this.createLink(data)}</a></td> :
															data.startsWith("linkper:") ? <td key={id2}><a target="_blank" href={"/personDetails/" + this.state.selectedCementry + "/" + this.createLinkPerson(data)[1]} >{this.createLinkPerson(data)[2]}</a></td> : <td key={id2}>{data}</td>

												))}
											</tr>
										)) : "keine Ergebnisse"}

									</tbody>
								</Table>
								{currentUser && (
									<div>
										<Row>
											<Col>
												<Button variant="secondary" onClick={(e) => this.createCsvList(e)} size="lg" block>Liste als CSV Datei speichern</Button>
											</Col>
										</Row>


										<Row>
											<Col>
												<Form.Group as={Col}>
													<Form.Label>Name der Liste</Form.Label>
													<Form.Control placeholder='geben Sie den Namen der Liste vor dem Speichern an' as="input" name='listName' id='listName' value={listNameValue} onChange={(e) => this.setState({ listNameValue: e.target.value })} />
												</Form.Group>
											</Col>
											<Col>
												<Button variant="secondary" onClick={(e) => this.saveList(e)} size="lg" block>Liste in Datenbank speichern</Button>
											</Col>
										</Row>
									</div>
								)}
							</div>
						) : ""}
						{this.state.message && (
							<div className="form-group">
								<div className={this.state.successful ? "alert alert-success" : "alert alert-danger"} role="alert">
									{this.state.message}
								</div>
							</div>
						)}

					</div>

				) : (
					<Row>
						<Col>Loading...</Col>

					</Row>
				)}


			</Container>)

	}
}
export default ListGenerator
