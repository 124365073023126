import React from 'react'
import { Row, Col } from "react-bootstrap";
import { personToString } from '../helpers/util'



export default class Highlight extends React.Component {
	
  	render() {
    	return (<div>
		
	        {this.props.searchResult.tombstones && this.props.searchResult.tombstones !==0 ? 
      			<Row>
					<Col>
						
						{this.props.searchResult.tombstones.map((tombstone, id) =>(	<div className="search-hit" key={id}>
						 	<strong>Friedhof: {tombstone.cementryName}</strong><br/>	
						 	<a href={"/tombstoneDetails/"+ tombstone.cementry+ "/" + tombstone.id}>
							<strong>Grabstein-ID:</strong> {tombstone.grabstein.grabsteinnummer ? tombstone.grabstein.grabsteinnummer:""}  
							</a>
							{tombstone.searchResultsHighlights.map((highlight, id2) =>(	<div key={id2}>
								<strong>{highlight.name}: </strong>
								{highlight.highlight.map((entry, k) => (<span key={k}> 
									{entry.split("<highlight>").map((token, j) => {
										if (j % 2 === 0) 
											return token
				            			else
                            				return <span className="highlight" key={j}>{token}</span>
									})}
								</span>))}										
							</div>))}  	
							{this.props.managerLinks && (<a href={"/management/tombstone/"+ tombstone.cementry+ "/" + tombstone.id}><br/>Grabstein Bearbeiten </a>)}			
						</div>))}
						
       				</Col>
      		</Row>
			: ""}
			{this.props.searchResult.persons &&  this.props.searchResult.persons.length !==0 ? 
      			<Row >
					<Col >
						{this.props.searchResult.persons.map((person, id) =>(	<div className="search-hit" key={id}>
						<strong>Friedhof: {person.cementryName}</strong><br/>	
						 <a href={"/personDetails/"+ person.cementry+ "/" + person.id}>
						<strong>Person: </strong>{personToString(person)}  
						</a>
						{person.searchResultsHighlights.map((highlight, id2) =>(	<div key={id2}>
								<strong>{highlight.name?highlight.name:"?"}: </strong>
								{highlight.highlight.map((entry, k) => (<span key={k}> 
									{entry.split("<highlight>").map((token, j) => {
										if (j % 2 === 0) 
											return token
				            			else
                            				return <span className="highlight" key={j}>{token}</span>
									})}
								</span>))}										
							</div>))} 
						{this.props.managerLinks && (<a href={"/management/person/"+ person.cementry+ "/" + person.id}>Person Bearbeiten </a>)}	
						</div>))}	

       				</Col>
      			</Row>
			: ""}
			{this.props.searchResult.maker &&  this.props.searchResult.maker.length !==0 ? 
      			<Row >
					<Col >
						{this.props.searchResult.maker.map((maker, id) =>(	<div className="search-hit" key={id}>
						<strong>Friedhof:</strong>
						 <a href={"/makerDetails/"+ maker.id}>
						<strong>Hersteller: </strong>{maker.vorname} {maker.name}  
						</a>
						{maker.searchResultsHighlights.map((highlight, id2) =>(	<div key={id2}>
								<strong>{highlight.name}: </strong>
								{highlight.highlight.map((entry, k) => (<span key={k}> 
									{entry.split("<highlight>").map((token, j) => {
										if (j % 2 === 0) 
											return token
				            			else
                            				return <span className="highlight" key={j}>{token}</span>
									})}
								</span>))}										
							</div>))} 
							{this.props.managerLinks && (<a href={"/makerDetails/"+ maker.id}>Hersteller Bearbeiten </a>)}
						</div>))}		
       				</Col>
      			</Row>
			: ""}
			</div>
			
    )
  }
}