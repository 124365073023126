import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";

import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";

export default function TombstoneGalery(props) {
	
	const [open, setOpen] = React.useState(false);
	const {photos} = props;
	const [slides, setSlides] = useState([]);
	
	useEffect(() => {
		
		
		let imageArray = []
		photos.map((item, key) => 
		     imageArray[key] = {"src": item.originalImagePath}
		)	
		
		setSlides(imageArray);	
	},[]);
	
	
    return (
      <Row>
      	<Col md={12}>
        {photos.map((item, key) => 
				<div key={key} className="imageGalery">
					<img key={key} width={200} style={{border: '0px', padding: '3px'}} src={item.path} alt='Grabstein' onClick={() => setOpen(true)}/>
					<br/>
					<span style={{fontSize: '10px'}}>{item.photographer} {' | '}{item.license?item.license:"keine Lizenz vorhanden"} <br/> Foto: {item.imageName}</span>
				</div>)}
		{open && (
			<Lightbox
        		open={open}
        		close={() => setOpen(false)}
        		slides={slides}
        		plugins={[Counter, Thumbnails]}
    			counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        	/>
        )}
        </Col>
      </Row>
    )
  }


