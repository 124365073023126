import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ImageGalery from "../../components/ImageGalery"
import StorageService from "../../services/storage.service";


export default function Desecration() {
	const [user, setUser] = useState(AuthService.getCurrentUser());

	const [isLoadingCementries, setLoadingCementries] = useState(true);
	const [cementries, setCementries] = useState([]);
	let storedCementry = StorageService.handleStorage("cementery")
	const [selectedCementry, setSelectedCementry] = useState(storedCementry ? storedCementry : 0);

	const [successful, setSuccessful] = useState(false);
	const [isLoadingDesecrations, setLoadingDesecrations] = useState(true);
	const [desecrations, setDesecrations] = useState([]);
	const [message, setMessage] = useState("");


	function handleCementryChange(e) {

		let id = e.id;
		setSelectedCementry(id);

		localStorage.setItem("cementery", JSON.stringify(id));
		setDesecrations([]);
		setLoadingDesecrations(true)


	}

	useEffect(() => {

		let selectedCementery = StorageService.handleStorage("cementery");
		setSelectedCementry(selectedCementery);
		setUser(AuthService.getCurrentUser());

		if (isLoadingCementries) {

			const user = AuthService.getCurrentUser();
			let onlyPublished = user ? "0" : "-2";

			ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
				response => {
					setCementries(response.data);
					setLoadingCementries(false);
					setSuccessful(true)

				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);
			setSelectedCementry(selectedCementery)
		}

		if (isLoadingDesecrations) {
			ElasticSearchService.loadAllDesecrations(selectedCementry).then(
				response => {
					console.log(selectedCementry)
					setDesecrations(response.data);
					setSuccessful(true);
					setLoadingDesecrations(false)
				},
				error => {
					const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
					setMessage(resMessage);
					setLoadingDesecrations(false);
					setSuccessful(false)
				}
			);
		}
	}, [isLoadingDesecrations, isLoadingCementries]);

	return (
	<div>
			{!isLoadingCementries && !isLoadingDesecrations ? (<Container>
				<Row>
					<Col>
						<Breadcrumb className="breadcrumb-style">
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							<Breadcrumb.Item href="/cemeteries/">Historische Friedhöfe</Breadcrumb.Item>
							<Breadcrumb.Item href={'/cemetery/' + selectedCementry}>{cementries.find(cementery => cementery.id === selectedCementry).label}</Breadcrumb.Item>
							<Breadcrumb.Item active>Friedhofsschändungen</Breadcrumb.Item>
							{user && (
							<Breadcrumb.Item href="/desecration">Neues Ereignis anlegen</Breadcrumb.Item>
							)}
							
							
							{console.log()}
							
						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col><h3>Friedhofsschändungen</h3></Col>
				</Row>


				<Row><Col></Col></Row>

				<Row >
					<Col>
						<Autocomplete
							id="combo-box-cementries"
							value={cementries.find(cementery => cementery.id === selectedCementry)}
							onChange={(event, newValue) => { handleCementryChange(newValue); }}
							disableClearable={true}
							options={cementries}
							renderInput={(params) => <TextField {...params} label="Wählen Sie einen Friedhof" />}
						/>
					</Col>
				</Row>

				{desecrations.map((desecration, index) => (
					<div className="desecration-content-border" key={index}>
						<Row >
							<Col>
								<strong>Datierung:</strong> <br />{desecration.date}
							</Col>
							<Col>
								<strong>Ereignis:</strong> <br />
								{desecration.event}
							</Col>
						</Row>
						<Row >
							<Col>

							</Col>
							<Col>
								<strong>Quellen:</strong> <br />
								{desecration.source}
							</Col>
						</Row>
						<Row >
							<Col>
								<strong>Scans:</strong> <br />
								{desecration.documents.length ? <ImageGalery photos={desecration.documents} /> : null}
							</Col>
						</Row>
						<Row>
							<Col className="col-auto me-auto">
							</Col>
							<Col className="col-auto">

								{user && (
									<a href={"/desecration/" + desecration.desecrationId} className="manager-link" >Ereignis bearbeiten</a>

								)}

							</Col>
						</Row>

					</div>

				))}


				{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">{message}</div>
					</div>
				)}
			</Container>) : (
				<Container>
					<Row className="text-spacer">
						<Col>
							<strong>Seite wird geladen... Bitte warten Sie!</strong>
						</Col>

					</Row>
					</Container>
				)}
				</div>
			)
			}