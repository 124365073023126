import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb, Form, Button, ListGroup } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import * as Constants from "../../components/constants";
import Autosuggest from 'react-autosuggest';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { personToString } from '../../helpers/util';
import NavigationButton from "../../components/navigationButton"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faUser } from '@fortawesome/free-solid-svg-icons'

import { getStringValue } from "../../helpers/util";
import FootnoteTextField from "./footnotes/footnoteTextField";
import ReferencesList from "./footnotes/referencesList";
import CopyRight from "./copyRight";
import ACService from "../../helpers/accessValidation.js"
import TombstoneConditionAssessment from "./assessment/tombstoneConditionAssessment2/tombstoneConditionAssessment2";


export default class Tombstone extends Component {

	constructor(props) {
		super(props);

		this.state = {
			startDate: new Date(),

			showManagerLinks: true,
			changeOrCancelButton: Constants.TOMBSTONE_CHANGE_BUTTON_INIT,
			changeOrCancelValue: Constants.BUTTON_INACTIV,
			readOnlySave: true,
			readOnly: true,
			cementryReadOnly: true,
			selectedFile: null,
			successfullImageUpload: false,

			photographer: "",
			photographerChoice: { id: "", type: "", value: "", comment: "" },

			cementries: [],
			cementryName: "",
			cementryId: "",
			cementryAbbreviation: "",
			tombstoneId: "",
			tombstone: {
				cementry: "",
				grabstein: { grabsteinnummer: "", grabsteinreihe: "" },
				tombstoneNumbers: [],
				carvedTombstoneNumbers: "",
				weltkoordinaten: { breitengrad: "", laengengrad: "", utm: "" },
				bilder: [],
				inschrift_hebraeisch: { vorderseite: { text: [], uebersetzung_1: [], uebersetzung_0: [] }, rueckseite: { text: [], uebersetzung_1: [], uebersetzung_0: [] }, sockel: { text: [], uebersetzung_1: [], uebersetzung_0: [] } },
				stilmittelChoice: [],
				stylistics: [],
				zitate: [],
				masse: {},
				symboleChoice: [],
				schmuck: [],
				material: { inschrifttafelChoice: [], sockelChoice: [], postamentChoice: [], steleChoice: [] },
				grabsteintypChoice: {},
				stilChoice: {},
				herstellerMaker: {},
				zustaende: [],
				restaurierungen: [],
				persons: [],
				quelle: [],
				literaturList: [],
			},
			literatur: "",
			stilmittel: "",
			symbol: "",
			ornament: "",
			hersteller: "",
			material: "",
			inschrifttafel: "",
			postament: "",
			sockel: "",
			schadensbild: "",

			isTombstoneLoading: true,
			isCementryLoading: true,
			isPersonListLoading: true,
			isNeighborsLoading: true,
			currentPerson: {},
			persons: [],
			previousTombstone: "",
			nextTombstone: "",
			choices: {},
			choiceKeys: [],
			makers: {},
			suggestions: [],
			personList: {},
			person: "",
			validated: false,

			isError: { grabsteinnummer: "" },
			successfull: false,
			readOnlyNew: false,
			alternativeTranslation: false,
			allReferences: [],
			germanRow: [],

		}


		this.getSuggestions = this.getSuggestions.bind(this);
		this.getCementryName = this.getCementryName.bind(this);
		this.checkInscriptionLineEmpty = this.checkInscriptionLineEmpty.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.dummy = this.dummy.bind(this);

		this.handleUpdateReferences = this.handleUpdateReferences.bind(this);
		this.handleCopyrightChangeForTombstone = this.handleCopyrightChangeForTombstone.bind(this);
		this.handleUpdateAssessments = this.handleUpdateAssessments.bind(this);

		this.getInscriptionRights = this.getInscriptionRights.bind(this);
		this.getImageRights = this.getImageRights.bind(this);
		this.getCommentRights = this.getCommentRights.bind(this);
		this.getCitationRights = this.getCitationRights.bind(this);
		this.getStilRights = this.getStilRights.bind(this);
		this.getStylisticRights = this.getStylisticRights.bind(this);
		this.getDimensionRights = this.getDimensionRights.bind(this);
		this.getTypeRights = this.getTypeRights.bind(this);
		this.getDescriptionRights = this.getDescriptionRights.bind(this);
		this.getSymbolsRights = this.getSymbolsRights.bind(this);
		this.getOrnamentRights = this.getOrnamentRights.bind(this);		
		this.getMakerRights = this.getMakerRights.bind(this);
		this.getMaterialRights = this.getMaterialRights.bind(this);
		this.getConditionRights = this.getConditionRights.bind(this);
		this.getRestaurationRights = this.getRestaurationRights.bind(this);
		this.getPersonRights = this.getPersonRights.bind(this);

	}

	dummy() {

	}


	addStylistic(e) {
		e.preventDefault();
		let tombstone = this.state.tombstone;
		tombstone.stylistics.push({ stylistic: "", line: "", comment: "" });
		this.setState({
			tombstone: tombstone
		});
	}

	deleteStylistic(e, id) {
		let tombstone = this.state.tombstone;
		let stylistic = tombstone.stylistics[id];
		if (stylistic.line === "" && stylistic.comment === "") {
			tombstone.stylistics.splice(id, 1);
			this.setState({
				tombstone: tombstone
			});
			return;
		}
		window.alert("Die Zeile ist nicht leer. Zum Löschen müssen alle Felder der Zeile leer sein.")
	}

	changeStylisticName(e, id) {
		let tombstone = this.state.tombstone
		let stylistic = tombstone.stylistics[id];
		stylistic.name = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	changeStylisticLine(e, id) {
		let tombstone = this.state.tombstone
		let stylistic = tombstone.stylistics[id];
		stylistic.line = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	changeStylisticComment(e, id) {
		let tombstone = this.state.tombstone
		let stylistic = tombstone.stylistics[id];
		stylistic.comment = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}


	handleCopyrightChangeForTombstone(rights) {

		let tombstone = this.state.tombstone;

		tombstone.rights = rights;

		this.setState({
			tombstone: tombstone
		});
	}



	alternativeTransition(e) {
		let flag = this.state.alternativeTranslation;

		if (Boolean(flag)) {
			flag = false;
		}
		else {
			flag = true;
		}
		this.setState({
			alternativeTranslation: flag
		})
	}

	handleCementryChange(e) {
		let id = e.target.value;
		let tombstone = this.state.tombstone;
		tombstone.cementry = id;

		this.setState({
			cementryId: id,
			tombstone: tombstone,
		});

		this.getCementryName(this.state.cementries, id);
	}

	changeCheckBox(e) {

		let tombstone = this.state.tombstone;

		if (Boolean(tombstone.allowAutoUpdate)) {
			tombstone.allowAutoUpdate = false;
		}
		else {
			tombstone.allowAutoUpdate = true;
		}
		this.setState({
			tombstone: tombstone
		})

	}

	changeCheckBoxGraveEnclosure(e) {

		let tombstone = this.state.tombstone;

		if (Boolean(tombstone.graveEnclosure)) {
			tombstone.graveEnclosure = false;
		}
		else {
			tombstone.graveEnclosure = true;
		}
		this.setState({
			tombstone: tombstone
		})

	}

	addTombstoneNumber() {
		let tombstone = this.state.tombstone;
		tombstone.tombstoneNumbers.push({ nummer: "", kommentar: "" })
		this.setState({
			tombstone: tombstone
		});
	}

	removeTombstoneNumber(id) {
		if (window.confirm("Soll die zusätzliche Grabsteinnummer hier entfernt werden?")) {
			let tombstone = this.state.tombstone;
			tombstone.tombstoneNumbers.splice(id, 1)
			this.setState({
				tombstone: tombstone
			});
		}

	}

	changeTombstoneNumber(e, id) {
		let tombstone = this.state.tombstone;
		let tombstoneNumbers = tombstone.tombstoneNumbers;

		tombstoneNumbers[id].nummer = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	changeTombstoneNumberComment(e, id) {
		let tombstone = this.state.tombstone;
		let tombstoneNumbers = tombstone.tombstoneNumbers;

		tombstoneNumbers[id].kommentar = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	changeZitatField(e, id) {
		let tombstone = this.state.tombstone
		let zitat = tombstone.zitate[id];
		zitat.zeile = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	changeZitatQuelleField(e, id) {
		let tombstone = this.state.tombstone
		let zitat = tombstone.zitate[id];
		zitat.quelle = e.target.value;
		this.setState({

			tombstone: tombstone
		});
	}

	changeAusfuehrung(e, id) {
		let tombstone = this.state.tombstone
		let restaurierung = tombstone.restaurierungen[id];
		restaurierung.ausfuehrung = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	changeDokumentation(e, id) {
		let tombstone = this.state.tombstone
		let restaurierung = tombstone.restaurierungen[id];
		restaurierung.dokumentation = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	removePerson(e, id) {
		if (window.confirm("Soll die Person hier entfernt werden?")) {
			let tombstone = this.state.tombstone

			let person = tombstone.persons;

			tombstone.removedPersons.push(person[id]);
			person.splice(id, 1)
			this.setState({
				person: "",
				tombstone: tombstone
			});
		}
	}

	onChangePerson = (event, { newValue }) => {
		event.preventDefault();
		this.setState({
			person: newValue,
		});

	};

	getSuggestionValueFromList(suggestion, field) {
		let tombstone = this.state.tombstone;
		let personField = tombstone[field];
		personField.push(suggestion)
		this.setState({
			tombstone: tombstone,
		});
		return personToString(suggestion);
	}



	getSuggestionsForPersons(value) {

		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const data = this.state.personList;
		if (inputLength === 0) {
			return []
		}
		else {
			//let suggestionValue = data.filter(person => personToString(person).toLowerCase().slice(0, inputLength) === inputValue);	
			let suggestionValue = data.filter(person => personToString(person).toLowerCase().search(inputValue.toLowerCase()) !== -1);
			return suggestionValue;
		}
	}

	changeSchadensbildComment(e, id, id2) {


		let tombstone = this.state.tombstone
		let zustand = tombstone.zustaende[id]
		let choice = zustand.schadensbilderChoice[id2];
		choice.comment = e.target.value;
		zustand.schadensbilderChoice[id2] = choice;
		tombstone.zustaende[id] = zustand;
		this.setState({
			tombstone: tombstone
		});
	}

	removeSchadensbild(e, id, id2) {
		e.preventDefault();
		if (window.confirm("Soll das Schadensbild hier entfernt werden?")) {
			let tombstone = this.state.tombstone
			let choices = tombstone.zustaende[id].schadensbilderChoice;
			choices.splice(id2, 1)
			this.setState({
				tombstone: tombstone
			});
		}
	}

	getSuggestionValueFromSchadensbildChoices(suggestion, id) {
		let tombstone = this.state.tombstone;
		let choices = tombstone.zustaende[id].schadensbilderChoice;
		if (choices === undefined || choices === null) {
			choices = [];
			tombstone.zustaende[id].schadensbilderChoice = choices;
		}


		choices.push(suggestion)
		this.setState({
			tombstone: tombstone,
		});
		return suggestion.value;
	}

	onChangeSchadensbild = (event, { newValue }) => {
		event.preventDefault();
		this.setState({
			schadensbild: newValue
		});
	}

	handleStateSelect(e, id) {
		let tombstone = this.state.tombstone;
		let zustand = tombstone.zustaende[id];
		zustand.einstufung = e.target.value
		this.setState({
			tombstone: tombstone
		});
	}

	handleDateChange(e, id) {
		let tombstone = this.state.tombstone;
		let zustand = tombstone.zustaende[id];
		zustand.datum = e
		this.setState({
			tombstone: tombstone
		});
	};

	handleUpdateAssessments(updatedAssessments) {

		let tombstone = this.state.tombstone;
		let updatedList = [...updatedAssessments];

		tombstone.conditions = updatedList;
		/*
		this.setState({
			tombstone: tombstone
		});*/
	}

	handleDateChangeRestaurierung(e, id) {
		let tombstone = this.state.tombstone;
		let restaurierung = tombstone.restaurierungen[id];
		restaurierung.datum = e
		this.setState({
			tombstone: tombstone
		});
	};

	getSuggestionValue(suggestion, field) {

		let tombstone = this.state.tombstone;
		tombstone[field] = suggestion;

		this.setState({
			tombstone: tombstone
		});
		return suggestion.value;
	}

	getSuggestionMakerValue(suggestion, field) {

		let tombstone = this.state.tombstone;
		tombstone[field] = suggestion;

		this.setState({
			tombstone: tombstone
		});
		return suggestion.displayName;
	}

	getSuggestionValueGrabsteinTyp(suggestion, field) {

		let tombstone = this.state.tombstone;
		tombstone[field] = suggestion;

		this.setState({
			tombstone: tombstone
		});
		return suggestion.value;
	}

	getSuggestionValueStil(suggestion, field) {

		let tombstone = this.state.tombstone;
		tombstone[field] = suggestion;

		this.setState({
			tombstone: tombstone
		});
		return suggestion.value;
	}

	onChangeHersteller = (event, { newValue }) => {

		event.preventDefault();
		let tombstone = this.state.tombstone;
		let herstellerMaker = tombstone.herstellerMaker

		if (herstellerMaker === null || herstellerMaker === undefined) {
			herstellerMaker = { id: "", name: "", vorname: "", displayName: "" }
		}

		herstellerMaker.displayName = newValue
		tombstone.herstellerMaker = herstellerMaker;
		this.setState({
			tombstone: tombstone
		});
	};

	onChangeGrabsteinTyp = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;
		let grabsteintypChoice = tombstone.grabsteintypChoice

		if (grabsteintypChoice === null || grabsteintypChoice === undefined) {
			grabsteintypChoice = { id: "", type: "", value: "", comment: "" }
		}

		grabsteintypChoice.value = newValue
		tombstone.grabsteintypChoice = grabsteintypChoice;
		this.setState({
			tombstone: tombstone
		});
	};

	onChangeStil = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;
		let stilChoice = tombstone.stilChoice

		if (stilChoice === null || stilChoice === undefined) {
			stilChoice = { id: "", type: "", value: "", comment: "" }
		}

		stilChoice.value = newValue
		tombstone.stilChoice = stilChoice;
		this.setState({
			tombstone: tombstone
		});
	};

	getSuggestionValueFromChoices(suggestion, field) {
		let tombstone = this.state.tombstone;
		let choices = tombstone[field];

		choices.push(suggestion)
		this.setState({
			tombstone: tombstone,
		});
		return suggestion.value;
	}

	getSuggestionValueFromMaterialChoices(suggestion, field) {
		let tombstone = this.state.tombstone;
		let choices = tombstone.material[field];
		choices.push(suggestion)
		this.setState({
			tombstone: tombstone,
		});
		return suggestion.value;
	}

	removeOrnament(e, id) {
		e.preventDefault();
		if (window.confirm("Soll das Ornament hier entfernt werden?")) {
			let tombstone = this.state.tombstone
			let ornamente = tombstone.schmuckChoice;
			ornamente.splice(id, 1)
			this.setState({
				tombstone: tombstone
			});
		}
	}

	removeSymbole(e, id) {
		e.preventDefault();
		if (window.confirm("Soll das Symbol hier entfernt werden?")) {
			let tombstone = this.state.tombstone
			let symbole = tombstone.symboleChoice;

			symbole.splice(id, 1);

			tombstone.symboleChoice = symbole;

			this.setState({
				tombstone: tombstone
			});
		}
	}

	removeStilmittel(e, id) {
		e.preventDefault();
		if (window.confirm("Soll das Stilmittel hier entfernt werden?")) {
			let tombstone = this.state.tombstone
			let stilmittel = tombstone.stilmittelChoice;
			stilmittel.splice(id, 1)
			this.setState({
				tombstone: tombstone
			});
		}
	}

	onChangeOrnament = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;

		this.setState({
			ornament: newValue,
			tombstone: tombstone
		});
	}

	onChangeMaterial = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;

		this.setState({
			material: newValue,
			tombstone: tombstone
		});
	}

	onChangeInschrifttafel = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;

		this.setState({
			inschrifttafel: newValue,
			tombstone: tombstone
		});
	}

	onChangePostament = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;

		this.setState({
			postament: newValue,
			tombstone: tombstone
		});
	}

	onChangeSockel = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;

		this.setState({
			sockel: newValue,
			tombstone: tombstone
		});
	}

	onChangeSymbol = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;

		this.setState({
			symbol: newValue,
			tombstone: tombstone
		});
	}

	onChangeStilmittel = (event, { newValue }) => {
		event.preventDefault();
		let tombstone = this.state.tombstone;

		this.setState({
			stilmittel: newValue,
			tombstone: tombstone
		});
	};

	changeComment(e, id) {
		let commentField = e.target.name;
		let tombstone = this.state.tombstone;

		switch (commentField) {
			case "stilmittel":
				let stilmittel = tombstone.stilmittelChoice[id];
				stilmittel.comment = e.target.value;
				break;
			default:
				break;
		}

		this.setState({
			tombstone: tombstone
		});
	}

	changePage(e, id) {
		let tombstone = this.state.tombstone;
		let literatur = tombstone.literaturList[id];

		literatur.page = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	changeQuelle(e, id) {
		let tombstone = this.state.tombstone;
		tombstone.quelle[id] = e.target.value;
		this.setState({
			tombstone: tombstone
		});
	}

	removeLiteratur(e, id) {
		e.preventDefault();
		if (window.confirm("Soll die Literaturangabe hier entfernt werden?")) {
			let tombstone = this.state.tombstone
			let literatur = tombstone.literaturList;
			literatur.splice(id, 1)
			this.setState({
				tombstone: tombstone
			});
		}
	}

	onChangeLiteratur = (event, { newValue }) => {
		event.preventDefault();
		this.setState({
			literatur: newValue,
		});
	}

	getSuggestionValueFromLiteraturChoice(suggestion, field) {
		let tombstone = this.state.tombstone;
		let literaturList = tombstone[field];
		literaturList.push({ "literaturEntry": suggestion, "literaturEntryId": suggestion.id, "page": "" })
		this.setState({
			tombstone: tombstone,
		});
		return suggestion.value;
	}

	changeField(e) {

		const value = e.target.value;
		const name = e.target.name;



		let tombstone = this.state.tombstone;

		if (name === "grabsteinnummer") {
			let grabstein = tombstone.grabstein;
			grabstein.grabsteinnummer = value;
			tombstone.grabstein = grabstein;
			this.setState({
				tombstone: tombstone,
				isError: { grabsteinnummer: "" },
			})
			return;
		}

		if (name === "grabsteinreihe") {
			let grabstein = tombstone.grabstein;
			grabstein.grabsteinreihe = value;
			tombstone.grabstein = grabstein;
			this.setState({
				tombstone: tombstone
			})
			return;
		}

		if (name === "utm") {
			let coordinates = tombstone.weltkoordinaten;
			coordinates.utm = value
			tombstone.weltkoordinaten = coordinates
			this.setState({
				tombstone: tombstone
			})
			return;
		}

		if (name === "numberOfWorkpieces") {
			tombstone.numberOfWorkpieces = value;
			this.setState({
				tombstone: tombstone
			})
			return;
		}

		if (name === "hoehe") {
			let masse = tombstone.masse;
			masse.hoehe = value
			tombstone.masse = masse
			this.setState({
				tombstone: tombstone
			})
			return;
		}

		if (name === "breite") {
			let masse = tombstone.masse;
			masse.breite = value
			tombstone.breite = masse
			this.setState({
				tombstone: tombstone
			})
			return;
		}

		if (name === "tiefe") {
			let masse = tombstone.masse;
			masse.tiefe = value
			tombstone.tiefe = masse
			this.setState({
				tombstone: tombstone
			})
			return;
		}

		if (name === "durchmesser") {
			let masse = tombstone.masse;
			masse.durchmesser = value
			tombstone.durchmesser = masse
			this.setState({
				tombstone: tombstone
			})
			return;
		}

		let isError = { grabsteinnummer: "" };

		this.setState(prevState => ({
			tombstone: { ...prevState.tombstone, [name]: value },
			isError: isError
		}))


	}

	removeMaterial(e, location, id) {
		let tombstone = this.state.tombstone;
		let selectedLocation = null;
		if (location === "stele") {
			selectedLocation = tombstone.material.steleChoice
			selectedLocation.splice(id, 1)
		}
		if (location === "inschrifttafel") {
			selectedLocation = tombstone.material.inschrifttafelChoice
			selectedLocation.splice(id, 1)
		}
		if (location === "postament") {
			selectedLocation = tombstone.material.postamentChoice
			selectedLocation.splice(id, 1)
		}
		if (location === "sockel") {
			selectedLocation = tombstone.material.sockelChoice
			selectedLocation.splice(id, 1)
		}

		this.setState({
			tombstone: tombstone
		});
	}


	changeInscriptionLine(e, location, id) {

		let part = e.target.name;
		let value = e.target.value;

		let tombstone = this.state.tombstone;
		let selectedLocation = null;
		if (location === "vorderseite") {
			selectedLocation = tombstone.inschrift_hebraeisch.vorderseite
		}
		if (location === "rueckseite") {
			selectedLocation = tombstone.inschrift_hebraeisch.rueckseite
		}
		if (location === "sockel") {
			selectedLocation = tombstone.inschrift_hebraeisch.sockel
		}

		switch (part) {
			case "text":
				selectedLocation.text[id] = value;
				break;
			case "uebersetzung_1":
				selectedLocation.uebersetzung_1[id] = value;
				break;
			case "uebersetzung_0":
				selectedLocation.uebersetzung_0[id] = value;
				break;
			default:
				break;
		}


		this.setState({
			tombstone: tombstone
		});
	}


	checkInscriptionLineEmpty(location, id) {

		let tombstone = this.state.tombstone;
		let selectedLocation = null;
		if (location === "vorderseite") {
			selectedLocation = tombstone.inschrift_hebraeisch.vorderseite
		}
		if (location === "rueckseite") {
			selectedLocation = tombstone.inschrift_hebraeisch.rueckseite
		}
		if (location === "sockel") {
			selectedLocation = tombstone.inschrift_hebraeisch.sockel
		}

		let text = selectedLocation.text[id];
		let u1 = selectedLocation.uebersetzung_1[id];
		let u0 = selectedLocation.uebersetzung_0[id];

		if (text === "" && u1 === "" && u0 === "") {
			return true
		}
		else {
			return false;
		}

	}

	deleteQuellenLine(e, id) {
		let tombstone = this.state.tombstone;
		let quelle = tombstone.quelle[id];

		if (quelle === "") {
			tombstone.quelle.splice(id, 1);
			this.setState({
				tombstone: tombstone
			});
			return;
		}
		window.alert("Die Zeile ist nicht leer. Zum Löschen müssen alle Felder der Zeile leer sein.")
	}

	deleteZitateLine(e, id) {
		let tombstone = this.state.tombstone;
		let zitat = tombstone.zitate[id];
		if (zitat.zeile === "" && zitat.quelle === "") {
			tombstone.zitate.splice(id, 1);
			this.setState({
				tombstone: tombstone
			});
			return;
		}
		window.alert("Die Zeile ist nicht leer. Zum Löschen müssen alle Felder der Zeile leer sein.")
	}

	deleteInscriptionLine(e, id, location) {
		e.preventDefault();
		let tombstone = this.state.tombstone;

		let result = this.checkInscriptionLineEmpty(location, id);
		if (result && location === "vorderseite") {
			let inscription = tombstone.inschrift_hebraeisch.vorderseite
			inscription.text.splice(id, 1);
			inscription.uebersetzung_1.splice(id, 1);
			inscription.uebersetzung_0.splice(id, 1);
			this.setState({
				tombstone: tombstone
			});
			return;
		}
		if (result && location === "sockel") {
			let inscription = tombstone.inschrift_hebraeisch.sockel
			inscription.text.splice(id, 1);
			inscription.uebersetzung_1.splice(id, 1);
			inscription.uebersetzung_0.splice(id, 1);
			this.setState({
				tombstone: tombstone
			});
			return;
		}
		if (result && location === "rueckseite") {
			let inscription = tombstone.inschrift_hebraeisch.rueckseite
			inscription.text.splice(id, 1);
			inscription.uebersetzung_1.splice(id, 1);
			inscription.uebersetzung_0.splice(id, 1);
			this.setState({
				tombstone: tombstone
			});
			return;
		}
		window.alert("Die Zeile ist nicht leer. Zum Löschen müssen alle Felder der Zeile leer sein.")

	}

	addZustand() {
		let tombstone = this.state.tombstone;
		tombstone.zustaende.push({ datum: null, schadensbilder: [], einstufung: "" })
		this.setState({
			tombstone: tombstone
		});
	}

	addRestaurierung() {
		let tombstone = this.state.tombstone;
		tombstone.restaurierungen.push({ datum: null, ausfuehrung: "", dokumentation: "" })
		this.setState({
			tombstone: tombstone
		});
	}

	removeRestaurierung(e, id) {
		if (window.confirm("Soll die Restaurierung entfernt werden?")) {
			let tombstone = this.state.tombstone;
			tombstone.restaurierungen.splice(id, 1)
			this.setState({
				tombstone: tombstone
			});
		}
	}

	removeZustand(e, id) {
		let tombstone = this.state.tombstone;
		tombstone.zustaende.splice(id, 1)
		this.setState({
			tombstone: tombstone
		});
	}

	addQuellenLine(e) {
		e.preventDefault();
		let tombstone = this.state.tombstone;
		tombstone.quelle.push("");
		this.setState({
			tombstone: tombstone
		});
	}

	addZitatLine(e) {
		e.preventDefault();
		let tombstone = this.state.tombstone;
		tombstone.zitate.push({ zitat: "", zeile: "", quelle: "" });
		this.setState({
			tombstone: tombstone
		});
	}

	addInscriptionLine(e, location) {
		e.preventDefault();
		let tombstone = this.state.tombstone;
		let selectedLocation = null;

		if (location === "vorderseite") {
			selectedLocation = tombstone.inschrift_hebraeisch.vorderseite
		}
		if (location === "rueckseite") {
			selectedLocation = tombstone.inschrift_hebraeisch.rueckseite
		}
		if (location === "sockel") {
			selectedLocation = tombstone.inschrift_hebraeisch.sockel
		}

		selectedLocation.text.push("");
		selectedLocation.uebersetzung_1.push("");
		selectedLocation.uebersetzung_0.push("");
		this.setState({
			tombstone: tombstone
		});
	}

	removeImage(e, id) {
		e.preventDefault();
		let imageName = this.state.tombstone.bilder[id].imageName;

		if (window.confirm("Soll das Bild " + imageName + " entfernt werden?")) {
			let tombstone = this.state.tombstone
			let images = tombstone.bilder;
			images.splice(id, 1)
			this.setState({
				tombstone: tombstone
			});
		}
	}

	getSuggestionValueFromTombstoneImageChoices(suggestion, id) {
		let tombstone = this.state.tombstone;
		let images = tombstone.bilder;
		let image = images[id];

		image.photographer = suggestion.value;
		image.license = suggestion.comment;
		images[id] = image;
		tombstone.bilder = images;
		this.setState({
			tombstone: tombstone,
		});
		return suggestion.value;
	}

	getSuggestions(value, key) {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const data = this.state.choices[key];




		if (inputLength === 0) {
			return []
		}
		else {
			//let suggestionValue = data.filter(ort => ort.value.toLowerCase().slice(0, inputLength) === inputValue);	
			let suggestionValue = data.filter(suggestion => suggestion.value.toLowerCase().search(inputValue.toLowerCase()) !== -1);
			return suggestionValue;
		}
	}

	getSuggestionsForMaker(value) {

		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		const makers = this.state.makers;
		if (inputLength === 0) {
			return []
		}
		else {
			return makers.filter(maker => maker.displayName.toLowerCase().search(inputValue.toLowerCase()) !== -1);
		}
	}

	onChangePhotographer = (event, { newValue }) => {
		event.preventDefault();
		if (event.target.name !== undefined) {
			let id = event.target.name;
			let tombstone = this.state.tombstone;
			let images = tombstone.bilder;
			let image = images[id];
			image.photographer = newValue;
			images[id] = image;
			tombstone.bilder = images;
			this.setState({
				tombstone: tombstone,
			});
		}
	};


	openFileDialog() {
		this.refs.fileUploader.click();
	}

	fileSelectedHandler(e) {
		e.preventDefault();
		let file = e.target.files[0];
		this.setState({
			selectedFile: file,
		})

		let tombstone = this.state.tombstone
		let imageName = file.name

		let formData = new FormData();
		formData.append('file', file, file.name);
		formData.append('tombstoneId', tombstone.id);

		ElasticSearchService.uploadImageFile(formData).then(
			response => {


				let tombstone = this.state.tombstone
				let availableImages = tombstone.bilder;
				let newImage = {
					"path": response.data.urlsmall,
					"imageName": imageName,
					"photographer": "",
					"originalImagePath": response.data.urlorg,
					"tumbnailImagePath": response.data.urltumb
				}
				availableImages.push(newImage);
				tombstone.bilder = availableImages



				this.setState({
					successfullImageUpload: true,
					message: response.data.message,
					tombstone: tombstone,
				})
				ElasticSearchService.saveTombstone(tombstone).then(
					response => {
						this.setState({
							message: response.data.message,
							successful: true,
							tombstone: tombstone,
						});

					},
					error => {
						this.setState({
							error:
								(error.response && error.response.data) ||
								error.message ||
								error.toString()
						});
					}
				);
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		)


	}

	enableChangeTombstone(e) {
		let value = e.target.value;
		if (value === "0") {
			this.setState({
				readOnly: false,
				readOnlySave: false,

				changeOrCancelButton: Constants.TOMBSTONE_CHANGE_BUTTON_ACTIV,
				changeOrCancelValue: Constants.BUTTON_ACTIV,

				message: "",
				successful: false,
			});
		}
		if (value === "1") {
			this.setState({
				readOnly: true,
				readOnlySave: true,

				changeOrCancelValue: Constants.BUTTON_INACTIV,
				changeOrCancelButton: Constants.TOMBSTONE_CHANGE_BUTTON_INIT,
				cementryReadOnly: true
			});
		}
	}

	getCementryName(cementries, cementryId) {
		let cementry = cementries.find(cementry => cementry.id === cementryId);
		this.setState({
			cementryId: cementry.id,
			cementryName: cementry.name,
			cementryAbbreviation: cementry.abbreviation

		})
	}

	componentDidMount() {



		const user = AuthService.getCurrentUser();
		if (user) {
			this.setState({
				currentUser: user,
				showManagerLinks: true,
			});
		}


		let url = window.location.href.split('/')
		let cementryId = url[5];
		let tombstoneId = url[6];
		let newFlag = "";
		if (url[7] !== null) {
			newFlag = url[7];
		}

		ElasticSearchService.loadCementries().then(
			response => {
				this.setState({
					cementries: response.data,
					isCementryLoading: false,
					cementryId: cementryId,
					tombstoneId: tombstoneId,
				});
				this.getCementryName(response.data, cementryId);
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}

		)



		if (newFlag === undefined || newFlag !== "new") {
			ElasticSearchService.loadTombstone(cementryId, tombstoneId).then(
				response => {
					this.setState({
						tombstone: response.data,
						allReferences: response.data.references,
						isTombstoneLoading: false,
						successfull: true,
					});


					if (response.data.grabstein.grabsteinnummer === undefined || response.data.grabstein.grabsteinnummer === null) {
						this.setState({
							successfull: false,

						});
					}

				},
				error => {

					this.setState({
						successfull: false,
						isTombstoneLoading: false,
						message: (error.response && error.response.data) || error.message || error.toString()
					});
				});

			ElasticSearchService.loadTombstoneNeighbors(cementryId, tombstoneId).then(
				response => {
					let neighbors = response.data;
					this.setState({
						previousTombstone: neighbors.previous,
						nextTombstone: neighbors.next,
						isNeighborsLoading: false
					});

				},
				error => {
					this.setState({
						error: (error.response && error.response.data) || error.message || error.toString()
					});
				}
			);
		}
		else {

			this.setState({
				readOnly: false,
				readOnlySave: false,
				changeOrCancelButton: Constants.TOMBSTONE_CHANGE_BUTTON_ACTIV,
				changeOrCancelValue: Constants.BUTTON_ACTIV,
				cementryReadOnly: false,
				isTombstoneLoading: false,
				successfull: true,
				isNeighborsLoading: false,
				readOnlyNew: true,
			});

		}

		ElasticSearchService.loadAllPersons().then(
			response => {
				this.setState({
					personList: response.data,
					isPersonListLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);

		ElasticSearchService.loadChoices().then(
			response => {
				this.setState({
					choices: response.data,
					choiceKeys: Object.keys(response.data)
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);

		ElasticSearchService.loadMakers().then(
			response => {

				this.setState({
					makers: response.data,
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	


	saveTombstone(e, tombstoneId) {
		e.preventDefault();
		let url = window.location.href.split('/')
		let newFlag = "";
		if (url[7] !== null) {
			newFlag = url[7];
		}

		if (!e.target.name === "next" || !e.target.name === "prev") {

			e.preventDefault();
		}

		let ts = this.state.tombstone;

		if (ts.cementry === undefined || ts.cementry === null || ts.cementry === "") {
			ts.cementry = this.state.cementryId;
			this.setState({
				tombstone: ts
			});
		}

		let grabstein = ts.grabstein;
		let hasCementryAbbreviation = false;
		if (grabstein.grabsteinnummer === "" || grabstein.grabsteinnummer.length < 3) {

			let isError = this.state.isError
			isError.grabsteinnummer = "Geben Sie eine vierstellige Grabsteinnumer an!"
			this.setState({
				isError: isError
			});
			e.stopPropagation();
			return;
		} else if (this.state.tombstoneId.indexOf("-") > -1) {

			hasCementryAbbreviation = true;
		} else {
			let isError = this.state.isError;
			isError.grabsteinnummer = "";
			this.setState({
				isError: isError
			});
		}

		if (newFlag === "new") {

			let tombstoneId = hasCementryAbbreviation ? grabstein.grabsteinnummer : (this.state.cementryAbbreviation.toLowerCase() + "-" + grabstein.grabsteinnummer);

			ElasticSearchService.tombstoneExist(ts.cementry, tombstoneId).then(
				response => {
					let exist = response.data;
					if (exist) {
						this.setState({
							message: "Der Grabstein existiert bereits und kann nicht als neuer Grabstein gespeichert werden."
						});
					}
					else {
						ElasticSearchService.saveTombstone(ts).then(
							response => {
								this.setState({
									message: response.data.message,
									successful: true,
									readOnly: true,
									readOnlySave: true,
									cementryReadOnly: true,
									tombstone: ts,
									changeOrCancelValue: Constants.BUTTON_INACTIV,
									changeOrCancelButton: Constants.TOMBSTONE_CHANGE_BUTTON_INIT,
								});
							},
							error => {
								this.setState({
									error: (error.response && error.response.data) || error.message || error.toString()
								});
							}
						);
					}
				},
				error => {
					this.setState({
						error:
							(error.response && error.response.data) ||
							error.message ||
							error.toString()
					});
				});
		}
		else {
			ElasticSearchService.saveTombstone(ts).then(
				response => {
					this.setState({
						message: response.data.message,
						successful: true,
						readOnly: true,
						readOnlySave: true,
						cementryReadOnly: true,

						tombstone: ts,
						changeOrCancelValue: Constants.BUTTON_INACTIV,
						changeOrCancelButton: Constants.TOMBSTONE_CHANGE_BUTTON_INIT,
					});

					if (tombstoneId !== undefined) {

						window.location.href = "/management/tombstone/" + this.state.cementryId + "/" + tombstoneId
					}

				},
				error => {
					this.setState({
						error:
							(error.response && error.response.data) ||
							error.message ||
							error.toString()
					});
				}
			);
		}
	}

	handleUpdateReferences(list) {

		let tombstone = this.state.tombstone;
		let references = [...list];

		tombstone.references = references;

		this.setState({
			tombstone: tombstone,
			allReferences: tombstone.references
		});
	}

	handleFieldContentChanged(fieldName, content) {

		let tombstone = this.state.tombstone;

		tombstone[fieldName] = content;

		this.setState({
			tombstone: tombstone
		});
	}
	
	
	getInscriptionRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_INSCRIPTIONS], this.state.cementryName);
	}
	
	getImageRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_IMAGES], this.state.cementryName);
	}
	
	getCommentRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_COMMENT], this.state.cementryName);
	}
	
	getCitationRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_CITATION], this.state.cementryName);
	}
	
	getStilRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_STIL], this.state.cementryName);
	}
	
	getStylisticRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_STYLISTIC], this.state.cementryName);
	}
	
	getDimensionRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_DIMENSION], this.state.cementryName);
	}
	
	getTypeRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_TYPE], this.state.cementryName);
	}
	
	getDescriptionRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_DESCRIPTION], this.state.cementryName);
	}
	
	getSymbolsRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_SYMBOLS], this.state.cementryName);
	}
	
	getOrnamentRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_ORNAMENT], this.state.cementryName);
	}
	
	getMakerRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_MAKER], this.state.cementryName);
	}
	getMaterialRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_MATERIAL], this.state.cementryName);
	}
	
	getConditionRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_CONDITIONS], this.state.cementryName);
	}
	
	getRestaurationRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_RESTAURATION], this.state.cementryName);
	}
	
	getPersonRights(){
		return ACService.accessValidation(this.state.currentUser, [Constants.AC_ADMIN_NAVIGATION,Constants.AC_MANAGER, Constants.AC_TOMBSTONE_MANAGER,Constants.AC_TOMBSTONE_PERSONS], this.state.cementryName);
	}
	

	render() {
		const {
			tombstone,
			isCementryLoading,
			isTombstoneLoading,
			isPersonListLoading,
			isNeighborsLoading,
			successfull,
			showManagerLinks,
			changeOrCancelButton,
			changeOrCancelValue,
			readOnlySave,
			readOnly,
			cementryReadOnly,
			cementryId,
			cementryAbbreviation,
			cementries,
			suggestions,
			literatur,
			symbol,
			ornament,
			material,
			inschrifttafel,
			postament,
			sockel,
			person,
			isError,
			nextTombstone,
			previousTombstone,
			readOnlyNew,
			alternativeTranslation,

		} = this.state;

		return (
			<Container>
				<Form noValidate onSubmit={(e) => this.saveTombstone(e)}>
					<Row><Col>
						<Breadcrumb className="breadcrumb-style">
							<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
							<Breadcrumb.Item >Management</Breadcrumb.Item>
							<Breadcrumb.Item className="breadcrumb-item-style" active>Grabstein</Breadcrumb.Item>
							<Breadcrumb.Item href="/search">Suche</Breadcrumb.Item>
						</Breadcrumb>
					</Col></Row>
					<Row>
						<Col>
							<h3>Grabsteinerfassung: {tombstone.id}</h3>
						</Col>
					</Row>

					{showManagerLinks && (
						<Row>
							<Col className="col-auto me-auto">
								<NavigationButton disabled={readOnlyNew} onClick={(e) => this.saveTombstone(e, previousTombstone)} name="prev" title="Vorheriger" linkBase="tombstone" cementryId={cementryId} tombstoneId={previousTombstone} myStyle="float-left" />
								<NavigationButton disabled={readOnlyNew} onClick={(e) => this.saveTombstone(e, nextTombstone)} name="next" title="Nächster" linkBase="tombstone" cementryId={cementryId} tombstoneId={nextTombstone} myStyle="float-right" />
								<Button variant="Link" value={changeOrCancelValue} onClick={(e) => this.enableChangeTombstone(e)}>{changeOrCancelButton}</Button>
								<Button variant="Link" type="submit" disabled={readOnlySave} className="float-right">Speichern</Button>
							</Col>
							<Col className="col-auto">
								<Button variant="Link" href={"/tombstoneDetails/" + cementryId + "/" + tombstone.id}>Grabstein Details</Button>
							</Col>

						</Row>
					)}
					<Row><Col></Col></Row>
					{this.state.message && (
						<div className="form-group">
							<div className={this.state.successful ? "alert alert-success" : "alert alert-danger"} role="alert">
								{this.state.message}
							</div>
						</div>
					)}
					{!isTombstoneLoading && !isCementryLoading && !isPersonListLoading && !isNeighborsLoading && successfull ? (
						<div>

							<div >
								<Row>
									<Col className="area">
										<fieldset disabled={cementryReadOnly}>
											<Form.Group as={Col} controlId="CementryChoice">
												<Form.Text className="text-muted"><strong>Friedhof</strong></Form.Text>
												<Form.Control as="select" defaultValue={cementryId} onChange={(e) => this.handleCementryChange(e)}>
													{cementries.map((cementry, id) => (
														<option key={cementry.id} value={cementry.id}>{cementry.name}</option>
													))}
												</Form.Control>
											</Form.Group>
										</fieldset>
									</Col>
								</Row>
								<Row>
									<Col className="area" md={2} >
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Friedhof-Kürzel</strong></Form.Text>
											<Form.Control className="form-control" as="input" name="abbreviation" value={cementryAbbreviation ? cementryAbbreviation : ""} readOnly={true} />
										</Form.Group>
									</Col>
									<Col className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Grabstein-ID</strong></Form.Text>
											<Form.Control className={isError.grabsteinnummer.length > 0 ? "is-invalid form-control" : "form-control"} as="input" name="grabsteinnummer" value={tombstone.grabstein.grabsteinnummer ? tombstone.grabstein.grabsteinnummer : ""} readOnly={!readOnlyNew} onChange={(e) => this.changeField(e)} />

											{isError.grabsteinnummer.length > 0 && (
												<span className="invalid-feedback">{isError.grabsteinnummer}</span>
											)}

										</Form.Group>
									</Col>
									<Col className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Grabsteinreihe</strong></Form.Text>
											<Form.Control as="input" name="grabsteinreihe" value={tombstone.grabstein.grabsteinreihe ? tombstone.grabstein.grabsteinreihe : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
										</Form.Group>
									</Col>
								</Row>
								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Eingemeißelte Grabsteinnummer</strong></Form.Text>
												<Form.Control className="form-control" as="input" name="carvedTombstoneNumber" value={tombstone.carvedTombstoneNumber ? tombstone.carvedTombstoneNumber : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>

									</Row>


									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Weitere Grabsteinnummern</strong></Form.Text>
											</Form.Group>
										</Col>

									</Row>

									{tombstone.tombstoneNumbers ? tombstone.tombstoneNumbers.map((number, id) => (
										<div key={id}>
											<Row>
												<Col xs={2}>
													<Form.Group as={Col} >
														<Form.Control as="input" name="tombstoneNumber" value={number.nummer ? number.nummer : ""} readOnly={readOnly} onChange={(e) => this.changeTombstoneNumber(e, id)} />
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control as="input" name="tombstoneNumberComment" value={number.kommentar ? number.kommentar : ""} readOnly={readOnly} onChange={(e) => this.changeTombstoneNumberComment(e, id)} />
													</Form.Group>
												</Col>
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right glyphicon glyphicon-star" onClick={(e) => this.removeTombstoneNumber(e, id)}>
															<FontAwesomeIcon icon={faTrash} />
														</Button>
													</Form.Group>
												</Col>
											</Row>
										</div>
									)) : ""}

									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addTombstoneNumber()}><FontAwesomeIcon icon={faPlus} /></Button>
										</Col>
									</Row>
								</div>



								<div className="areaBorder">
									{ACService.accessValidation(this.state.currentUser, Constants.AC_EDIT_TOMBSTONE_IMAGES_ONLY, this.state.cementryName) ? (
										<Row>
											<Col className="area">
												<Form.Group as={Col} >
													<Form.Text className="text-muted"><strong>Bilder</strong></Form.Text>
												</Form.Group>
											</Col>
										</Row>
									) : ('')}

									<Row>


										{tombstone.bilder ? tombstone.bilder.map((image, id) => (
											<Col xs={3} key={id}>

												<Form.Group as={Col} >
													<Form.Text className="text-muted">{id === 0 ? <strong>Standardbild:</strong> : <strong>Bild:</strong>} {image.imageName}</Form.Text><br />
													<img src={image.path} width="150" alt="kein Bild vorhanden" /><br />
													
													
													{this.getImageRights()?(
													<fieldset disabled={readOnly}>
													<Form.Text className="text-muted">Fotograf:</Form.Text>
														<Autosuggest
															suggestions={suggestions}
															onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Fotograf") }) }}
															onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
															getSuggestionValue={(suggestion) => this.getSuggestionValueFromTombstoneImageChoices(suggestion, id)}
															renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
															inputProps={{ placeholder: 'Fotograf auswählen', value: image.photographer ? image.photographer : "", onChange: this.onChangePhotographer }}
															renderInputComponent={(inputProps) => <Form.Control size="100px" as="input" name={id} {...inputProps} />}
														/>
													</fieldset>
													):('')}
													Lizenz: {image.license ? image.license : "keine Lizenz vorhanden"}
{this.getImageRights()?(
													<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.removeImage(e, id)}><FontAwesomeIcon icon={faTrash} /></Button>
													):('')}
												</Form.Group>

											</Col>
										)) : ""}
									</Row>
									{this.getImageRights()?(
									<Row>
										<Col>
											<Form.Group as={Col} >
												<div disabled={readOnly} className="add-media" onClick={!readOnly ? this.openFileDialog.bind(this) : this.dummy()}>
													<input type="file" id="file" ref="fileUploader" style={{ display: "none" }} onChange={(e) => this.fileSelectedHandler(e)} />
													<Button disabled={readOnly} variant="Link" className="btn float-right"><FontAwesomeIcon icon={faPlus} /></Button>
												</div>

											</Form.Group>
										</Col>
									</Row>
									):('')}
								</div>

{this.getInscriptionRights()?(<div>
								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Inschrift - Vorderseite</strong></Form.Text>
											</Form.Group>
										</Col>
										<Form.Group as={Col} controlId="alternativeTranslation">
											<Form.Check className="btn float-right" type="checkbox" checked={Boolean(alternativeTranslation) ? true : false} label="Alternative Übersetzung Anzeigen" name="alternativeTranslation" disabled={readOnly} onChange={(e) => this.alternativeTransition(e)} />
										</Form.Group>
									</Row>
									<Row>
										<Col xs={1}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Zeile</strong></Form.Text>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Inschrift</strong></Form.Text>
											</Form.Group>
										</Col>

										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Übersetzung</strong></Form.Text>
											</Form.Group>
										</Col>
										{alternativeTranslation && (
											<Col>
												<Form.Group as={Col} >
													<Form.Text className="text-muted"><strong>Alternative Übersetzung</strong></Form.Text>
												</Form.Group>
											</Col>
										)}
										<Col xs={1}></Col>

									</Row>

									{tombstone.inschrift_hebraeisch && tombstone.inschrift_hebraeisch.vorderseite && tombstone.inschrift_hebraeisch.vorderseite.text ? tombstone.inschrift_hebraeisch.vorderseite.text.map((line, id) => (
										<div key={id}>
											<Row>
												<Col xs={1}>
													<Form.Group as={Col} >
														{id + 1}
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control dir="auto" size="sm" as="input" name="text" value={line} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "vorderseite", id)} />
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control size="sm" as="textarea" rows={tombstone.inschrift_hebraeisch.vorderseite.uebersetzung_1[id].length > 50 ? 2 : 1} name="uebersetzung_1" value={tombstone.inschrift_hebraeisch.vorderseite.uebersetzung_1[id] ? tombstone.inschrift_hebraeisch.vorderseite.uebersetzung_1[id] : ""} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "vorderseite", id)} />
													</Form.Group>
												</Col>
												{alternativeTranslation && (
													<Col>
														<Form.Group as={Col} >
															<Form.Control size="sm" as="input" name="uebersetzung_0" value={tombstone.inschrift_hebraeisch.vorderseite.uebersetzung_0[id] ? tombstone.inschrift_hebraeisch.vorderseite.uebersetzung_0[id] : ""} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "vorderseite", id)} />
														</Form.Group>
													</Col>
												)}
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.deleteInscriptionLine(e, id, "vorderseite")}><FontAwesomeIcon icon={faTrash} /></Button>
													</Form.Group>
												</Col>
											</Row>
										</div>)) : ""}

									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addInscriptionLine(e, "vorderseite")}><FontAwesomeIcon icon={faPlus} /></Button>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Inschrift - Postament</strong></Form.Text>
											</Form.Group>

										</Col>
									</Row>
									<Row>
										<Col xs={1}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Zeile</strong></Form.Text>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Inschrift</strong></Form.Text>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Übersetzung</strong></Form.Text>
											</Form.Group>
										</Col>
										{alternativeTranslation && (
											<Col>
												<Form.Group as={Col} >
													<Form.Text className="text-muted"><strong>Alternative Übersetzung</strong></Form.Text>
												</Form.Group>
											</Col>
										)}
										<Col xs={1}>
										</Col>
									</Row>
									{tombstone.inschrift_hebraeisch && tombstone.inschrift_hebraeisch.sockel && tombstone.inschrift_hebraeisch.sockel.text ? tombstone.inschrift_hebraeisch.sockel.text.map((line, id) => (
										<div key={id}>
											<Row>
												<Col xs={1}>
													<Form.Group as={Col} >
														{id + 1}
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control dir="auto" size="sm" as="input" name="text" value={line} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "sockel", id)} />
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control size="sm" as="textarea" rows={tombstone.inschrift_hebraeisch.sockel.uebersetzung_1[id].length > 50 ? 2 : 1} name="uebersetzung_1" value={tombstone.inschrift_hebraeisch.sockel.uebersetzung_1[id] ? tombstone.inschrift_hebraeisch.sockel.uebersetzung_1[id] : ""} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "sockel", id)} />
													</Form.Group>
												</Col>
												{alternativeTranslation && (
													<Col>
														<Form.Group as={Col} >
															<Form.Control size="sm" as="input" name="uebersetzung_0" value={tombstone.inschrift_hebraeisch.sockel.uebersetzung_0[id] ? tombstone.inschrift_hebraeisch.sockel.uebersetzung_0[id] : ""} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "sockel", id)} />
														</Form.Group>
													</Col>
												)}
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.deleteInscriptionLine(e, id, "sockel")}><FontAwesomeIcon icon={faTrash} /></Button>
													</Form.Group>
												</Col>
											</Row>
										</div>)) : ""}

									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addInscriptionLine(e, "sockel")}><FontAwesomeIcon icon={faPlus} /></Button>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Inschrift - Rückseite</strong></Form.Text>
											</Form.Group>

										</Col>
									</Row>
									<Row>
										<Col xs={1}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Zeile</strong></Form.Text>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Inschrift</strong></Form.Text>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Übersetzung</strong></Form.Text>
											</Form.Group>
										</Col>
										{alternativeTranslation && (
											<Col>
												<Form.Group as={Col} >
													<Form.Text className="text-muted"><strong>Alternative Übersetzung</strong></Form.Text>
												</Form.Group>
											</Col>
										)}
										<Col xs={1}>
										</Col>
									</Row>
									{tombstone.inschrift_hebraeisch && tombstone.inschrift_hebraeisch.rueckseite && tombstone.inschrift_hebraeisch.rueckseite.text ? tombstone.inschrift_hebraeisch.rueckseite.text.map((line, id) => (
										<div key={id}>
											<Row>
												<Col xs={1}>
													<Form.Group as={Col} >
														{id + 1}
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control dir="auto" size="sm" as="input" name="text" value={line} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "rueckseite", id)} />
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control size="sm" as="textarea" rows={tombstone.inschrift_hebraeisch.rueckseite.uebersetzung_1[id].length > 50 ? 2 : 1} name="uebersetzung_1" value={tombstone.inschrift_hebraeisch.rueckseite.uebersetzung_1[id] ? tombstone.inschrift_hebraeisch.rueckseite.uebersetzung_1[id] : ""} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "rueckseite", id)} />
													</Form.Group>
												</Col>
												{alternativeTranslation && (
													<Col>
														<Form.Group as={Col} >
															<Form.Control size="sm" as="input" name="uebersetzung_0" value={tombstone.inschrift_hebraeisch.rueckseite.uebersetzung_0[id] ? tombstone.inschrift_hebraeisch.rueckseite.uebersetzung_0[id] : ""} readOnly={readOnly} onChange={(e) => this.changeInscriptionLine(e, "rueckseite", id)} />
														</Form.Group>
													</Col>
												)}
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.deleteInscriptionLine(e, id, "rueckseite")}><FontAwesomeIcon icon={faTrash} /></Button>
													</Form.Group>
												</Col>
											</Row>
										</div>)) : ""}

									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addInscriptionLine(e, "rueckseite")}><FontAwesomeIcon icon={faPlus} /></Button>
										</Col>
									</Row>
									<Row>
										<Col>
											<CopyRight
												readOnly={readOnly}
												fieldName="inscription"
												rights={tombstone.rights}
												onChange={(rights) => this.handleCopyrightChangeForTombstone(rights)}
												cementeryName={this.state.cementryName}
											/>

										</Col>
									</Row>


								</div>
			</div>
):('')}
{this.getCommentRights()?(
								<Row>
									<Col className="area">
										<FootnoteTextField
											globalReferences={tombstone.references}
											footnoteLetter="G"
											fieldName="kommentar"
											onUpdateReferences={this.handleUpdateReferences}

											disabled={readOnly}
											fullWidth
											label="Kommentar"
											name="comment-with-footnotes"
											defaultValue={getStringValue(tombstone.kommentar, "")}
											onContentChanged={(fieldName, content) => this.handleFieldContentChanged(fieldName, content)}
											multiline
											rows={4}
										/>
									</Col>
								</Row>
):('')}								
{this.getStylisticRights()?(



								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Stilmittel</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={3}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Bezeichnung</Form.Text>
											</Form.Group>
										</Col>
										<Col xs={1}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Zeile</Form.Text>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Kommentar</Form.Text>
											</Form.Group>
										</Col>
									</Row>
									{tombstone.stylistics ? tombstone.stylistics.map((stylistic, id) => (
										<div key={id}>
											<Row>
												<Col xs={3}>
													<Form.Group as={Col} >
														<Form.Control as="input" name="stylistic-name" value={stylistic.name ? stylistic.name : ""} readOnly={readOnly} onChange={(e) => this.changeStylisticName(e, id)} />
													</Form.Group>
												</Col>
												<Col xs={1}>
													<Form.Group as={Col} >
														<Form.Control as="input" name="stylistic-line" value={stylistic.line ? stylistic.line : ""} readOnly={readOnly} onChange={(e) => this.changeStylisticLine(e, id)} />
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control as="input" name="stylistic-comment" value={stylistic.comment ? stylistic.comment : ""} readOnly={readOnly} onChange={(e) => this.changeStylisticComment(e, id)} />
													</Form.Group>
												</Col>
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.deleteStylistic(e, id)}><FontAwesomeIcon icon={faTrash} /></Button>
													</Form.Group>
												</Col>
											</Row>
										</div>)) : ""}
									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addStylistic(e)}><FontAwesomeIcon icon={faPlus} /></Button>
										</Col>
									</Row>
								</div>

								):('')}	
{this.getCitationRights()?(


								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Zitate</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={1}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Zeile</Form.Text>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Quelle</Form.Text>
											</Form.Group>
										</Col>
									</Row>
									{tombstone.zitate ? tombstone.zitate.map((zitat, id) => (
										<div key={id}>
											<Row>
												<Col xs={1}>
													<Form.Group as={Col} >
														<Form.Control as="input" name="zitatZeile" value={zitat.zeile ? zitat.zeile : ""} readOnly={readOnly} onChange={(e) => this.changeZitatField(e, id)} />
													</Form.Group>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Control as="input" name="zitatQuelle" value={zitat.quelle ? zitat.quelle : ""} readOnly={readOnly} onChange={(e) => this.changeZitatQuelleField(e, id)} />
													</Form.Group>
												</Col>
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.deleteZitateLine(e, id)}><FontAwesomeIcon icon={faTrash} /></Button>
													</Form.Group>
												</Col>
											</Row>
										</div>)) : ""}
									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addZitatLine(e)}><FontAwesomeIcon icon={faPlus} /></Button>
										</Col>
									</Row>
								</div>
):('')}	

{this.getDimensionRights()?(

							
								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Maße (in cm)</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Höhe</Form.Text>
												<Form.Control as="input" name="hoehe" value={tombstone.masse && tombstone.masse.hoehe ? tombstone.masse.hoehe : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Breite</Form.Text>
												<Form.Control as="input" name="breite" value={tombstone.masse && tombstone.masse.breite ? tombstone.masse.breite : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Tiefe</Form.Text>
												<Form.Control as="input" name="tiefe" value={tombstone.masse && tombstone.masse.tiefe ? tombstone.masse.tiefe : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Durchmesser</Form.Text>
												<Form.Control as="input" name="durchmesser" value={tombstone.masse && tombstone.masse.durchmesser ? tombstone.masse.durchmesser : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
									</Row>
								</div>
):('')}


								<Row>
								{this.getTypeRights()?(
									<Col className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Grabsteintyp</strong></Form.Text>
											<fieldset disabled={readOnly}>
												<Autosuggest
													suggestions={suggestions}
													onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Grabsteintyp") }) }}
													onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
													getSuggestionValue={(suggestion) => this.getSuggestionValueGrabsteinTyp(suggestion, "grabsteintypChoice")}
													renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
													inputProps={{ placeholder: 'Grabsteintyp auswählen', value: tombstone.grabsteintypChoice && tombstone.grabsteintypChoice.value ? tombstone.grabsteintypChoice.value : "", onChange: this.onChangeGrabsteinTyp }}
													renderInputComponent={(inputProps) => <Form.Control as="input" name="grabsteintyp" {...inputProps} />}
												/>
											</fieldset>
										</Form.Group>
									</Col>
		):('')}
		{this.getStilRights()?(
									<Col className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Stil</strong></Form.Text>
											<fieldset disabled={readOnly}>
												<Autosuggest
													suggestions={suggestions}
													onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Stil") }) }}
													onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
													getSuggestionValue={(suggestion) => this.getSuggestionValueStil(suggestion, "stilChoice")}
													renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
													inputProps={{ placeholder: 'Stil auswählen', value: tombstone.stilChoice && tombstone.stilChoice.value ? tombstone.stilChoice.value : "", onChange: this.onChangeStil }}
													renderInputComponent={(inputProps) => <Form.Control as="input" name="stil" {...inputProps} />}
												/>
											</fieldset>
										</Form.Group>
									</Col>
									):('')}
								</Row>
								
		{this.getDescriptionRights()?(

								<Row>
									<Col className="area">
										<Form.Group as={Col} >
											<FootnoteTextField
												globalReferences={tombstone.references}
												footnoteLetter="G"
												fieldName="beschreibung"
												onUpdateReferences={this.handleUpdateReferences}

												disabled={readOnly}
												fullWidth
												label="Beschreibung"
												name="description-with-footnotes"
												defaultValue={getStringValue(tombstone.beschreibung, "")}
												onContentChanged={(fieldName, content) => this.handleFieldContentChanged(fieldName, content)}
												multiline
												rows={4}
											/>
										</Form.Group>
									</Col>
								</Row>
	):('')}
	{this.getSymbolsRights()?(
	
								<Row>
									<Col xs={6} className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Darstellungen und Symbole</strong></Form.Text>
											<fieldset disabled={readOnly}>
												<ListGroup variant="flush">

													{tombstone.symboleChoice ? tombstone.symboleChoice.map((symbole, id) => (
														<ListGroup.Item key={id} >
															<div key={id} onClick={(e) => !readOnly && this.removeSymbole(e, id)}>{symbole.value}</div>
														</ListGroup.Item>
													)) : ""}

												</ListGroup>
											</fieldset>
										</Form.Group>
									</Col>
									<Col className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Symbole</strong></Form.Text>
											<fieldset disabled={readOnly}>
												<Autosuggest
													suggestions={suggestions}
													onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Symbol") }) }}
													onSuggestionsClearRequested={() => { this.setState({ suggestions: [], symbol: "" }) }}
													getSuggestionValue={(suggestion) => this.getSuggestionValueFromChoices(suggestion, "symboleChoice")}
													renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
													inputProps={{ placeholder: 'Symbol auswählen', value: symbol, onChange: this.onChangeSymbol }}
													renderInputComponent={(inputProps) => <Form.Control as="input" name="symbole" {...inputProps} />}
												/>
											</fieldset>
										</Form.Group>
									</Col>
								</Row>
):('')}
{this.getOrnamentRights()?(

								<Row>
									<Col xs={6} className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Schmuck und Ornamente</strong></Form.Text>
											<ListGroup variant="flush">
												{tombstone.schmuckChoice ? tombstone.schmuckChoice.map((ornament, id) => (
													<ListGroup.Item key={id} >
														<div key={id} onClick={(e) => !readOnly && this.removeOrnament(e, id)}>{ornament.value}</div>
													</ListGroup.Item>
												)) : ""}
											</ListGroup>
										</Form.Group>
									</Col >
									<Col className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Ornamente</strong></Form.Text>
											<fieldset disabled={readOnly}>
												<Autosuggest
													suggestions={suggestions}
													onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Ornament") }) }}
													onSuggestionsClearRequested={() => { this.setState({ suggestions: [], ornament: "" }) }}
													getSuggestionValue={(suggestion) => this.getSuggestionValueFromChoices(suggestion, "schmuckChoice")}
													renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
													inputProps={{ placeholder: 'Ornament auswählen', value: ornament, onChange: this.onChangeOrnament }}
													renderInputComponent={(inputProps) => <Form.Control as="input" name="ornament" {...inputProps} />}
												/>
											</fieldset>
										</Form.Group>
									</Col>
								</Row>
):('')}

{this.getMakerRights()?(


									
									
								<Row >
									<Col className="area">
										<Form.Group as={Col} >
											<Form.Text className="text-muted"><strong>Hersteller</strong></Form.Text>
											<fieldset disabled={readOnly }>
												<Autosuggest
													suggestions={suggestions}
													onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestionsForMaker(value) }) }}
													onSuggestionsClearRequested={() => { this.setState({ suggestions: [] }) }}
													getSuggestionValue={(suggestion) => this.getSuggestionMakerValue(suggestion, "herstellerMaker")}
													renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.displayName}</ListGroup.Item>}
													inputProps={{ placeholder: 'Hersteller auswählen', value: tombstone.herstellerMaker && tombstone.herstellerMaker.displayName ? tombstone.herstellerMaker.displayName : "", onChange: this.onChangeHersteller }}
													renderInputComponent={(inputProps) => <Form.Control as="input" name="hersteller" {...inputProps} />}
												/>
											</fieldset>
										</Form.Group>
									</Col>
								</Row>
):('')}
{this.getMaterialRights()?(
								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Material</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Stele</strong></Form.Text>
												<ListGroup variant="flush">
													{tombstone.material && tombstone.material.steleChoice ? tombstone.material.steleChoice.map((material, id) => (
														<ListGroup.Item key={id} >
															<div key={id} onClick={(e) => !readOnly && this.removeMaterial(e, "stele", id)}>{material.value}</div>
														</ListGroup.Item>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Material</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Material") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], material: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromMaterialChoices(suggestion, "steleChoice")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Material auswählen', value: material, onChange: this.onChangeMaterial }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="material" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>

									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Inschrifttafel</strong></Form.Text>
												<ListGroup variant="flush">
													{tombstone.material && tombstone.material.inschrifttafelChoice ? tombstone.material.inschrifttafelChoice.map((material, id) => (
														<ListGroup.Item key={id} >
															<div key={id} onClick={(e) => !readOnly && this.removeMaterial(e, "inschrifttafel", id)}>{material.value}</div>
														</ListGroup.Item>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Material</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Material") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], inschrifttafel: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromMaterialChoices(suggestion, "inschrifttafelChoice")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Material auswählen', value: inschrifttafel, onChange: this.onChangeInschrifttafel }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="inschrifttafel" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Postament</strong></Form.Text>
												<ListGroup variant="flush">
													{tombstone.material && tombstone.material.postamentChoice ? tombstone.material.postamentChoice.map((material, id) => (
														<ListGroup.Item key={id} >
															<div key={id} onClick={(e) => !readOnly && this.removeMaterial(e, "postament", id)}>{material.value}</div>
														</ListGroup.Item>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Material</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Material") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], postament: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromMaterialChoices(suggestion, "postamentChoice")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Material auswählen', value: postament, onChange: this.onChangePostament }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="postament" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Sockel</strong></Form.Text>
												<ListGroup variant="flush">
													{tombstone.material && tombstone.material.sockelChoice ? tombstone.material.sockelChoice.map((material, id) => (
														<ListGroup.Item key={id} >
															<div key={id} onClick={(e) => !readOnly && this.removeMaterial(e, "sockel", id)}>{material.value}</div>
														</ListGroup.Item>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Material</strong></Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Material") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], sockel: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromMaterialChoices(suggestion, "sockelChoice")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Material auswählen', value: sockel, onChange: this.onChangeSockel }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="sockel" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group as={Col} controlId="graveEnclosure">
												<Form.Check type="checkbox" checked={Boolean(tombstone.graveEnclosure) ? true : false}
													label="Grabsteineinfassung vorhanden" name="graveEnclosure" disabled={readOnly} onChange={(e) => this.changeCheckBoxGraveEnclosure(e)} />
											</Form.Group>
										</Col>
									</Row>
								</div>

								):('')}
								
{this.getConditionRights()?(
	<div>							
	<div className="areaBorder">

									<Row>
										<Col>
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Anzahl Werkstücke</Form.Text>
												<Form.Control as="input" name="numberOfWorkpieces" value={tombstone.numberOfWorkpieces ? tombstone.numberOfWorkpieces : ""} readOnly={readOnly} onChange={(e) => this.changeField(e)} />
											</Form.Group>
										</Col>
									</Row>
								</div>

								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Zustandsbewertung</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>

									{tombstone.conditions ? (
										<Row>
											<Col>
												<TombstoneConditionAssessment
													readOnly={readOnly}
													conditions={tombstone.conditions}
													tombstoneNumber={tombstone.grabstein.grabsteinnummer}
													canSelectTombstone={false}
													showTombstoneName={false}
													onUpdateAssessments={this.handleUpdateAssessments}
												/>
											</Col>
										</Row>
									) : ("")}
								</div>
								</div>

):('')}		
{this.getRestaurationRights()?(

								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Restaurierungen</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									{tombstone.restaurierungen ? tombstone.restaurierungen.map((restaurierung, id) => (
										<div key={id}>
											<Row>
												<Col xs={2}>
													<Form.Group as={Col} >
														<Form.Text className="text-muted">Datum</Form.Text>
														<DatePicker
															disabled={readOnly}
															dateFormat="dd/MM/yyyy"
															selected={Date.parse(restaurierung.datum)}
															onChange={(e) => this.handleDateChangeRestaurierung(e, id)}
															isClearable
															className="form-control"
														/>
													</Form.Group>
												</Col>
												<Col xs={1}>
												</Col>
												<Col>
													<Form.Group as={Col} >
														<Form.Text className="text-muted">Ausführung</Form.Text>
														<Form.Control placeholder='Ausführung' as="input" name="ausfuehrung" value={restaurierung.ausfuehrung ? restaurierung.ausfuehrung : ""} readOnly={readOnly} onChange={(e) => this.changeAusfuehrung(e, id)} />
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col>
													<Form.Group as={Col} >
														<Form.Text className="text-muted">Dokumentation</Form.Text>
														<Form.Control placeholder='Dokumentation' as="textarea" size="3" name="dokumentation" value={restaurierung.dokumentation ? restaurierung.dokumentation : ""} readOnly={readOnly} onChange={(e) => this.changeDokumentation(e, id)} />
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col>
													<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.removeRestaurierung(e, id)}><FontAwesomeIcon icon={faTrash} /></Button>
												</Col>
											</Row>

										</div>)) : ""}
									<Row>
										<Col>
											<Form.Group as={Col} >
												<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addRestaurierung(e)}><FontAwesomeIcon icon={faPlus} /></Button>
											</Form.Group>
										</Col>
									</Row>
								</div>
):('')}		
{this.getPersonRights()?(

								<div className="areaBorder">
									<Row>
										<Col className="area" xs={6}>
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Personen</strong></Form.Text>
												<ListGroup variant="flush">
													{tombstone.persons ? tombstone.persons.map((person, id) => (
														<div key={id}>
															<ListGroup.Item disabled={readOnly} key={id} action onClick={(e) => this.removePerson(e, id)}>{personToString(person)}</ListGroup.Item>
															<Button variant="Link" href={"/management/person/" + cementryId + "/" + person.id}><FontAwesomeIcon icon={faUser} /></Button>
														</div>
													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col>
										<Col >
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Personenauswahl</Form.Text>
												<fieldset disabled={readOnly}>

													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestionsForPersons(value) }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], person: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromList(suggestion, "persons")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{personToString(suggestion)}</ListGroup.Item>}
														inputProps={{ placeholder: 'Person auswählen', value: person, onChange: this.onChangePerson }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="person" {...inputProps} />}

													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
):('')}
								<div className="areaBorder">
									<Row>
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Quelle</strong></Form.Text>
											</Form.Group>
										</Col>
									</Row>
									{tombstone.quelle ? tombstone.quelle.map((quelle, id) => (
										<div key={id}>
											<Row>
												<Col>
													<Form.Group as={Col} >
														<Form.Control as="input" name="quellenZeile" value={quelle ? quelle : ""} readOnly={readOnly} onChange={(e) => this.changeQuelle(e, id)} />
													</Form.Group>
												</Col>
												<Col xs={1}>
													<Form.Group as={Col} >
														<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.deleteQuellenLine(e, id)}><FontAwesomeIcon icon={faTrash} /></Button>
													</Form.Group>
												</Col>
											</Row>
										</div>)) : ""}
									<Row>
										<Col>
											<Button disabled={readOnly} variant="Link" className="btn float-right" onClick={(e) => this.addQuellenLine(e)}><FontAwesomeIcon icon={faPlus} /></Button>
										</Col>
									</Row>
								</div>
								<div className="areaBorder">
									<Row>
										<Col xs={6} className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted"><strong>Literatur</strong></Form.Text>
												<ListGroup variant="flush">
													{tombstone.literaturList ? tombstone.literaturList.map((entry, id) => (
														<ListGroup.Item key={id} >
															<div onClick={(e) => !readOnly && this.removeLiteratur(e, id)}>{entry.literaturEntry.value}</div>
															<Form.Control as="input" placeholder="Seite" value={entry.page ? entry.page : ""} name="literatur" readOnly={readOnly} onChange={(e) => this.changePage(e, id)} />
														</ListGroup.Item>

													)) : ""}
												</ListGroup>
											</Form.Group>
										</Col >
										<Col className="area">
											<Form.Group as={Col} >
												<Form.Text className="text-muted">Literaturauswahl</Form.Text>
												<fieldset disabled={readOnly}>
													<Autosuggest
														suggestions={suggestions}
														onSuggestionsFetchRequested={({ value }) => { this.setState({ suggestions: this.getSuggestions(value, "Literatur") }) }}
														onSuggestionsClearRequested={() => { this.setState({ suggestions: [], literatur: "" }) }}
														getSuggestionValue={(suggestion) => this.getSuggestionValueFromLiteraturChoice(suggestion, "literaturList")}
														renderSuggestion={(suggestion) => <ListGroup.Item variant="light">{suggestion.value}</ListGroup.Item>}
														inputProps={{ placeholder: 'Literaturangabe auswählen', value: literatur, onChange: this.onChangeLiteratur }}
														renderInputComponent={(inputProps) => <Form.Control as="input" name="literatur" {...inputProps} />}
													/>
												</fieldset>
											</Form.Group>
										</Col>
									</Row>
								</div>
								{this.state.allReferences.length > 0 &&
									<div className="areaBorder">
										<Row>
											<Col>
												<ReferencesList
													title="Einzelnachweise"
													references={tombstone.references}
													onUpdateReferences={this.handleUpdateReferences}
													isReadOnly={readOnly}
												/>
											</Col>
										</Row>
									</div>
								}
								<Row>
									<Col>
										<Form.Group as={Col} controlId="allowAutoUpdate">
											<Form.Check type="checkbox" checked={Boolean(tombstone.allowAutoUpdate) ? true : false} label="Automatische Updates erlauben." name="allowAutoUpdate" disabled={readOnly} onChange={(e) => this.changeCheckBox(e)} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group as={Col} >
											<Form.Text className="text-muted">Zuletzt importiert</Form.Text>
											{tombstone.timestamp}
										</Form.Group>
									</Col>
								</Row>
							</div>
							<Row><Col></Col></Row>
							{showManagerLinks && (
								<Row>
									<Col className="col-auto me-auto">
										<NavigationButton disabled={readOnlyNew} onClick={(e) => this.saveTombstone(e, previousTombstone)} name="prev" title="Vorheriger" linkBase="tombstone" cementryId={cementryId} tombstoneId={previousTombstone} myStyle="float-left" />
										<NavigationButton disabled={readOnlyNew} onClick={(e) => this.saveTombstone(e, nextTombstone)} name="next" title="Nächster" linkBase="tombstone" cementryId={cementryId} tombstoneId={nextTombstone} myStyle="float-right" />
										<Button variant="Link" value={changeOrCancelValue} onClick={(e) => this.enableChangeTombstone(e)}>{changeOrCancelButton}</Button>
										<Button variant="Link" type="submit" disabled={readOnlySave} className="float-right">Speichern</Button>
									</Col>
									<Col className="col-auto">
										<Button variant="Link" href={"/tombstoneDetails/" + cementryId + "/" + tombstone.id}>Grabstein Details</Button>
									</Col>

								</Row>
							)}
						</div>
					) :
						(
							<Row>
								<Col>Loading...</Col>
							</Row>
						)}
					<Row id="footer">
						<Col>

						</Col>
						<Col>

						</Col>
					</Row>
				</Form>
			</Container>
		)
	}
}
