import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";

import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";

export default function ImageGalery(props) {

	const [open, setOpen] = React.useState(false);
	const { photos } = props;
	const [slides, setSlides] = useState([]);

	useEffect(() => {


		let imageArray = []
		photos.map((item, key) =>
			imageArray[key] = { "src": item }
		)

		setSlides(imageArray);
	}, []);


	return (
		<Row>
			<Col md={12}>
				{photos.map((item, key) =>
					<div key={key} className="imageGalery">
						<div class="image-galerie-container">
							<img key={key}  src={item} alt='Grabstein' onClick={() => setOpen(true)} />{' '}
						</div>
					</div>)}
				{open && (
					<Lightbox
						open={open}
						close={() => setOpen(false)}
						slides={slides}
						plugins={[Counter, Thumbnails]}
						counter={{ container: { style: { top: "unset", bottom: 0 } } }}
					/>
				)}
			</Col>
		</Row>
	)
}


