import React, { useState, useEffect } from "react";
import { useMatch, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Breadcrumb, Form, Button, Spinner } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StorageService from "../../services/storage.service";



export default function Desecration() {
	const match = useMatch('desecration/:id');
	const navigate = useNavigate();

	const [user, setUser] = useState(AuthService.getCurrentUser());

	const [isLoadingCementries, setLoadingCementries] = useState(true);
	const [cementries, setCementries] = useState([]);
	const [selectedCementry, setSelectedCementry] = useState(0);
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [desecration, setDesecration] = useState({});
	const [desecrationDocuments, setDesecrationDocuments] = useState([]);
	const [isLoadingDesecration, setLoadingDesecration] = useState(true);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [isHidden, setHidden] = useState(false);

	const [images, setImages] = useState([]);


	const VisuallyHiddenInput = styled('input')({
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)',
		height: 1,
		overflow: 'hidden',
		position: 'inherit',
		bottom: 0,
		left: 0,
		whiteSpace: 'nowrap',
		width: 3,
	});





	const handleFileSelection = (event) => {
		const newSelectedFiles = Array.from(event.target.files);

		const newImages = newSelectedFiles.map((file) => {
			return {
				name: file.name,
				url: URL.createObjectURL(file),
			};
		});

		setImages([...images, ...newImages]);

		selectedFiles.push(...newSelectedFiles)
		setSelectedFiles(selectedFiles);



	};

	function saveDesecration(event) {

		setHidden(true);
		if (selectedCementry === "0") {
			console.log("kein Friedhof")
		}


		const formData = new FormData();
		formData.append('desecrationId', desecration.desecrationId);
		formData.append('files', []);
		formData.append('cemeteryId', selectedCementry);
		formData.append('date', desecration.date);
		formData.append('event', desecration.event);
		formData.append('source', desecration.source);
		formData.append('documents', desecration.documents);


		if (selectedFiles) {
			selectedFiles.forEach((file, index) => {
				formData.append('files', selectedFiles[index]);
			});

		} else {
			console.error('No files selected');
		}
		ElasticSearchService.saveDesecration(formData).then(
			response => {

				setDesecration(response.data)
				setMessage("Ereignis erfolgreich gespeichert!");
				setSuccessful(true);
				setHidden(false);

			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);

			}
		)

		setTimeout(() => {
			setMessage(null);
		}, 5000);
	};


	function changeField(e) {

		let fieldName = e.target.name;
		let value = e.target.value;

		if (fieldName === "source") {
			setDesecration({...desecration, source: value});

		}
		if (fieldName === "event") {
			setDesecration({...desecration, event: value});

		}

		if (fieldName === "date") {
			setDesecration({...desecration, date: value});

		}
	}




	function handleCementryChange(e) {

		let id = e.id;
		setSelectedCementry(id);

		localStorage.setItem("cementery", JSON.stringify(id));


	}

	function prepareImages(img) {
		const newImages = img.map((url) => {
			return {
				name: "",
				url: url
			};
		});

		setImages(newImages);
	}

	useEffect(() => {

		let selectedCementery = StorageService.handleStorage("cementery");
		setSelectedCementry(selectedCementery);

		console.log(selectedCementery);
		setUser(AuthService.getCurrentUser());

		if (match && isLoadingDesecration) {
			const id = match.params.id;

			ElasticSearchService.loadDesecrationById(id).then(
				response => {
					setDesecration(response.data);
					setDesecrationDocuments(response.data.documents);
					setLoadingDesecration(false);
					setSuccessful(true)

				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingDesecration(false);
				}
			);
		}


		if (isLoadingCementries) {

			const user = AuthService.getCurrentUser();
			let onlyPublished = user ? "0" : "-2";

			ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
				response => {
					setCementries(response.data);
					setLoadingCementries(false);
					setSuccessful(true)

				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);

		}
	}, [isLoadingCementries, isLoadingDesecration]);

	const deleteNewImage = (e, index) => {
		e.preventDefault();

		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages);

		const newSelectedFiles = [...selectedFiles];
		newSelectedFiles.splice(index, 1);
		setSelectedFiles(newSelectedFiles);

	};

	function newEvent(event) {
		window.location.reload()
	}

	const deleteOldImage = (e, index) => {
		e.preventDefault();


		let images = desecrationDocuments;
		const newImages = [...images];
		newImages.splice(index, 1);

		setDesecrationDocuments(newImages);
		desecration.documents = newImages;
		setDesecration(desecration);
	};

	return (

		<div>
			{user && !isLoadingCementries && !isLoadingCementries ? (<Container>
				<Row>
					<Col>
						<Breadcrumb className="breadcrumb-style">
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							<Breadcrumb.Item>Management</Breadcrumb.Item>
							<Breadcrumb.Item href="/desecrationList">Friedhofsschändungen</Breadcrumb.Item>
							<Breadcrumb.Item active >Ereignis</Breadcrumb.Item>
							<Breadcrumb.Item href="/desecration">Neues Ereignis anlegen</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col><h3>Friedhofsschändungen</h3></Col>
				</Row>


				<Row className="text-spacer">
					<Col>Ereignis ID: {desecration.desecrationId}</Col>
				</Row>
				<Row><Col></Col></Row>
				<Row className="text-spacer">
					<Col>
						<Autocomplete
							id="combo-box-cementries"
							value={selectedCementry ? cementries.find(cementery => cementery.id === selectedCementry) : "0"}
							onChange={(event, newValue) => { handleCementryChange(newValue); }}
							disableClearable={true}
							options={cementries}
							renderInput={(params) => <TextField {...params} label="Wählen Sie einen Friedhof" />}
						/>
					</Col>
				</Row>
				<Row className="text-spacer">
					<Col>
						<Form.Group as={Col} >
							<Form.Text className="text-muted"><strong>Datum im Format:dd.mm.yyyy (24.04.2021) oder yyyy, Monatsname (2024, April) </strong></Form.Text>
							<Form.Control as="input" name="date" value={desecration.date} onChange={(e) => changeField(e)} />
						</Form.Group>
					</Col>
				</Row>

				<Row className="text-spacer">
					<Col>
						<Form.Group as={Col} >
							<Form.Text className="text-muted"><strong>Ereignis</strong></Form.Text>
							<Form.Control as="textarea" name="event" value={desecration.event} onChange={(e) => changeField(e)} rows={15} />
						</Form.Group>

					</Col>
				</Row>
				<Row className="text-spacer">
					<Col>
						<Form.Group as={Col} >
							<Form.Text className="text-muted"><strong>Quelle</strong></Form.Text>
							<Form.Control as="textarea" name="source" value={desecration.source} onChange={(e) => changeField(e)} rows={3}/>
						</Form.Group>
					</Col>
				</Row>
				<Row><Col></Col></Row>

				{desecrationDocuments && desecrationDocuments.length > 0 && (
					<Row className="text-spacer">
						<Col>
							<Form.Group as={Col} >
								<Form.Text className="text-muted"><strong>Vorhandene Bilder:</strong></Form.Text>
							</Form.Group>
							{desecrationDocuments.map((image, index) => (
								<span key={index} >

									<a href="" onClick={(e) => deleteOldImage(e, index)} >
										<img key={index} src={image} title="Löschen" style={{ maxHeight: '100px' }} /> {' '}
									</a>
								</span>

							))}
						</Col>
					</Row>
				)}
				<Row><Col></Col></Row>
				<Row className="text-spacer">
					<Col className="text-center">

						<MuiButton
							id="upload-button"
							component="label"
							role={undefined}
							variant="contained"
							tabIndex={-1}
							startIcon={<CloudUploadIcon />}
						>
							Dateiauswahl
							<VisuallyHiddenInput
								type="file"
								onChange={(event) => handleFileSelection(event)}
								multiple
							/>
						</MuiButton>

					</Col>
				</Row>
				<Row><Col></Col></Row>

				{images.length > 0 && (
					<Row className="text-spacer">
						<Col>

							<Form.Group as={Col} >
								<Form.Text className="text-muted"><strong>Neue Bilder:</strong></Form.Text>
							</Form.Group>
							{images.map((image, index) => (
								<span key={index}>
									<img key={index} src={image.url} title="Löschen" style={{ maxHeight: '100px' }} onClick={(e) => deleteNewImage(e, index)} /> {' '}
								</span>
							))}
						</Col>
					</Row>
				)}
				<Row><Col></Col></Row>
				<Row className="text-spacer">
					<Col className="col-auto me-auto">
						<Button variant="primary" href={"/desecration"}>Abbrechen</Button>
						{' '}
						<Button variant="primary" className="float-right" href={"/desecration"}>Neues Ereignis anlegen</Button>
					</Col>
					<Col className="col-auto">
						{!isHidden ? (
							<Button variant="primary" className="float-right" onClick={(event) => saveDesecration(event)} >Speichern</Button>
						) : (
							<Spinner animation="border" variant="primary" />
						)}
					</Col>
				</Row>


				{message && (
					<div className="text-spacer">
						<div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">{message}</div>
					</div>
				)}


			</Container>) : (
				<Container>
					<Row className="text-spacer">
						<Col>
							<strong>Sie müssen angemeldet sein, um diese Seite nutzen zu können:</strong>
						</Col>

					</Row>
				</Container>
			)}
		</div>

	)
}