import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Container, Row, Col, Breadcrumb} from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";

import parse from 'html-react-parser'

export default function Cementery(props) {
	
	const { onCementeryLoaded } = props;
	
	const { cementeryId } = useParams();

	const [isLoadingCementery, setLoadingCementery] = useState(true);
	const [cementery, setCementery] = useState({});
	
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
	
	const [description, setDescription] = useState("");
	

	
	useEffect(() => {
		
		if (onCementeryLoaded !== undefined) onCementeryLoaded(cementeryId);
		
		localStorage.setItem("cementery", JSON.stringify(cementeryId));
		
		if(isLoadingCementery){
			
			
			ElasticSearchService.getCementry(cementeryId).then(
			response => {
			
				setCementery(response.data);
				setDescription(response.data.description)
				setLoadingCementery(false);
				setSuccessful(true)
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementery(false);
				}
			);
		}

	},[isLoadingCementery] );
			
	return (
		<Container fluid="xxl">
		<div className="text-spacer">
			<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item href="/cemeteries/">Historische Friedhöfe</Breadcrumb.Item>
					  <Breadcrumb.Item active>{cementery.name}</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style"  href="/desecrationList">Friedhofsschändungen</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style"  href="/tombstones">Grabsteinliste</Breadcrumb.Item>  
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col><h3>Jüdischer Friedhof {cementery.name} ({cementery.abbreviation})</h3></Col>
			</Row>
			
			<Row>
				<Col>
					Denkmalnummer: <br/>
					Regierungsbezirk: <br/>
					Landkreis: <br/>
					Adresse:<br/>
					Belegung: <br/>
					Anzahl Grabsteine: <br/>
				</Col>
				<Col>
					{cementery.monumentNumber}<br/>
					{cementery.gov}<br/>
					{cementery.district}<br/>
					{cementery.ownerAddress? cementery.ownerAddress.street +' '+ cementery.ownerAddress.houseNumber+', '+ cementery.ownerAddress.postCode +' '+ cementery.ownerAddress.city :""}<br/>
					{cementery.occupancy}<br/>
					{cementery.numberOfTombstones}<br/>
				</Col>
			</Row>			
			<Row><Col>{parse(description)}</Col></Row>

	
			
			{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
			)}
		</div>
		</Container>
	);
}