import React, { useState, useEffect } from "react";

import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function RadioRating(props) {

	const { readOnly, labels, labelSelected, onChange } = props;
		
	const [value, setValue] = useState(labelSelected);
	const [hover, setHover] = useState(-1);
	
	const [ratings, setRatings] = useState([]);
	
	function handleChange(event) {
	
		let labelValue = event.target.value;
		
		setValue(labelValue);
					
		if (onChange) onChange(labelValue);
	}
		
	useEffect(() => {
		
		let currentRatings = Object.entries(labels).map((entry) => {
			return entry[1];
		});
		
		setRatings(currentRatings);
			
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<RadioGroup
		        row 
		        aria-labelledby="demo-controlled-radio-buttons-group"
		        name="controlled-radio-buttons-group"
		        value={value}
		        onChange={handleChange} 
		        			
		      >
		      {
		      	ratings.map((rating, ratingIndex) => (
				  <FormControlLabel key={ratingIndex} value={rating} control={<Radio />} label={rating} disabled={readOnly}	/>
				))
			  }
		      </RadioGroup>
		</Box>
	);
}