import React from 'react'
import { Row, Col } from "react-bootstrap";
import { checkArray } from '../helpers/util';

export default class DetailElementStylistic extends React.Component {
	
  	render() {
    	return (<div>
	        {checkArray(this.props.value) ? 
      			<Row >
					<Col md={3} style={{backgroundColor: '#ffffff'}}>
						<strong>{this.props.name}</strong>
       				</Col>
					<Col  md="auto" style={{backgroundColor: '#ffffff'}}></Col>
					<Col md={{ span: 10, offset: 1 }} style={{backgroundColor: '#fbfbfb'}}>
						{this.props.value.map((entry, id) =>(	<div key={id}>{entry.name} - Zeile {entry.line}: {entry.comment}</div>))}
       				</Col>
      		</Row>
			: ""}</div>
			
    )
  }
}

