import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import './jfb-styles-3.0.css';
import './App.css';
import * as Constants from "./components/constants";
import ACService from "./helpers/accessValidation.js";
import { disableForms } from "./helpers/jfb.js";

import { Container, Navbar, Nav, NavDropdown, Row, Col, Button } from "react-bootstrap";

import AuthService from "./services/auth.service";
import Home from "./elements/home";
import Impressum from "./elements/public/impressum";
import Contact from "./elements/public/contact";
import BetOlam from "./elements/public/betOlam";
import Error from "./elements/error";
import Login from "./elements/public/login";
import Faq from "./elements/public/faq";

import CementryOverview from "./elements/cementeries/cementryOverview";
import CementryDetails from "./elements/cementeries/cementryDetails";

import Password from "./elements/users/password";
import UserManagement from "./elements/users/userManagement";
import UserDetails from "./elements/users/userDetails";


import Cementeries from "./elements/public/cementeries";
import Cementery from "./elements/public/cementery";
import Tombstones from "./elements/public/tombstones";
import TombstoneDetails from "./elements/public/tombstoneDetails";
import Tombstone from "./elements/management/tombstone";
import Person from "./elements/management/person";
import SearchResult from "./elements/public/searchResults";
import PersonDetails from "./elements/public/personDetails";
import ListGenerator from "./elements/management/listGenerator";
import Reports from "./elements/management/reports";
import Desecration from "./elements/management/desecration";
import DesecrationList from "./elements/management/desecrationList";

import MeasurementManagement from "./elements/measurement/measurementManagement";

import MakerList from "./elements/maker/makerList";
import MakerDetails from "./elements/maker/makerDetails";
import Choices from "./elements/choices/choices";

import ConsentDialog from "./elements/dialogs/consentDialog";
import { handleStorage, setStorage } from './helpers/jfb'



import PhotoUpload from "./elements/measurement/photoUpload";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ElasticSearchService from "./services/elasticSearch.service";
import Footnotes from "./elements/management/footnotes";
import Assessments from "./elements/management/assessments";

import CementryReport from "./elements/analysis/cementryReport";

import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

import ConsentRejected from "./elements/public/consentRejected";
import Dashboard from "./elements/public/dashboard";

import Conservation from "./elements/management/conservation";
import ConservationProjects from "./elements/management/conservation/v2/conservationProjects";

function JFBApp() {

	const { pathname, hash, key } = useLocation();

	const [user] = useState(AuthService.getCurrentUser());

	const [isUserLoggedOut, setUserLoggedOut] = useState(user === null);

	const [open, setOpen] = useState(false);
	const [cementriesForPersons, setCementriesForPersons] = useState([]);
	const [selectedCemetryDialogForPersons, setSelectedCemetryDialogForPersons] = useState('');
	const [isLoadingCementries, setLoadingCementries] = useState(true);

	const [openTombstoneDialog, setOpenTombstoneDialog] = useState(false);
	const [cementriesForTombstones, setCementriesForTombstones] = useState([]);
	const [selectedCemetryDialogForTombstones, setSelectedCemetryDialogForTombstones] = useState('');

	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");

	const [headerImage, setHeaderImage] = useState('jfb-homeheader-01.jpg');
	
	const [openConsentDialog, setOpenConsentDialog] = useState(false);


  	const [googleMapConsent] = useState(handleStorage("googleMapConsent").length!==0 ? Boolean(handleStorage("googleMapConsent")) : false);
  	const [requiredConsent] = useState(handleStorage("requiredConsent").length!==0 ? Boolean(handleStorage("requiredConsent")) : false);
  	const [lastConsentConfirmation] = useState(handleStorage("lastConsentConfirmation"));
  	

	const parseJwt = (token) => {
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} catch (e) {
			return null;
		}
	};


	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	const handleCancelDialog = () => {
		setOpen(false);
		setSelectedCemetryDialogForPersons('');
	};
	
	
	const handleOpenConsentDialog = () => {
		setOpenConsentDialog(true);
	};

	const handleCancelConsentDialog = () => {

		window.location.reload(false);
		
		
	};
	const handleApprovalConsentDialog = () => {
		setOpenConsentDialog(false);
		
	};
	
	
	

	function handleCementryChangeDialogForPersons(e) {
		let id = e.id;

		setSelectedCemetryDialogForPersons(id);
	}

	function handleCementryChangeDialogForTombstones(e) {
		let id = e.id;

		setSelectedCemetryDialogForTombstones(id);
	}

	const handleOpenTombstoneDialog = () => {
		setOpenTombstoneDialog(true);
	};

	const handleCloseTombstoneDialog = () => {
		setOpenTombstoneDialog(false);
	};

	const navigate = useNavigate();

	function handleConsentRejected() {
		navigate('/consentRejected', { replace: true });
	}

	function updateHeaderImage(cementeryId) {

		let headerImage = "jfb/" + cementeryId + ".jpg";

		checkImage(headerImage).then(
			response => {

				if (response.status !== "error") setHeaderImage(headerImage);
			},
		);
	}

	const checkImage = path =>
		new Promise(resolve => {
			const img = new Image();
			img.onload = () => resolve({ path, status: 'ok' });
			img.onerror = () => resolve({ path, status: 'error' });

			img.src = path;
		});

	function getNavigation() {

		return <Navbar collapseOnSelect expand="true" >
			<Container>
				<Navbar.Brand>{''}</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">

					<Nav className="me-auto" style={{ backgroundColor: '#FFFFFF', marginTop: '10px', paddingLeft: '10px', textColor: '#f9f6f1' }}>
						<Nav.Link href="/">Home</Nav.Link>
						
						<Nav.Link href="/cemeteries">Friedhöfe</Nav.Link>
						<Nav.Link href="/tombstones">Grabsteine</Nav.Link>
						<Nav.Link href="/search">Suche</Nav.Link>
						<Nav.Link href="/listGenerator">Individuelle Auswertungen</Nav.Link>
						<Nav.Link href="/faq">Fragen und Antworten</Nav.Link>
						<Nav.Link href="/betolam">Hintergründe</Nav.Link>
						<Nav.Link href="/contact">Kontakt</Nav.Link>
						<Nav.Link href="/impress">Impressum</Nav.Link>

						<NavDropdown.Divider />

						{user === null && (
							<Nav.Link className="nav-link" href="/login">Login</Nav.Link>
						)}
						{user !== null && (
							<div>
								{ACService.accessValidation(user, Constants.AC_ADMIN_NAVIGATION) && (
									<NavDropdown className="nav-right menu-bold" title="Administration" id="basic-nav-dropdown">
										<NavDropdown.Item href="/userManagement">Benutzerübersicht</NavDropdown.Item>
										<NavDropdown.Item href="/register">Neuen Benutzer anlegen</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item href="/measurementManagement">Vermessungsdatenmanagement</NavDropdown.Item>
										<NavDropdown.Item href="/photoUpload">Bilddatenmanagement</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item onClick={handleOpenTombstoneDialog}>Grabstein erfassen</NavDropdown.Item>
										<NavDropdown.Item href="/cemeteryOverview">Friedhöfe</NavDropdown.Item>
										<NavDropdown.Item href="/desecration">Neue Friedhofsschändung erfassen</NavDropdown.Item>
										<NavDropdown.Item href="/desecrationList">Friedhofsschändungen</NavDropdown.Item>
									</NavDropdown>
								)}

								{ACService.accessValidation(user, [Constants.AC_ADMIN_NAVIGATION, Constants.AC_MANAGER_NAVIGATION,] ) && (


									<NavDropdown className="nav-right menu-bold" title="Management" id="basic-nav-dropdown">
										<NavDropdown.Item onClick={handleOpenDialog} >Person erfassen</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item href="/choices">Auswahllisten</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item href={"/makerDetails/new"} >Hersteller erfassen</NavDropdown.Item>
										<NavDropdown.Item href="/makers">Hersteller Übersicht</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item href="/reports">Auswertungen</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item href="/conservation-projects">Konservierungen</NavDropdown.Item>
									</NavDropdown>
								)}
								{(ACService.accessValidation(user, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidation(user, Constants.AC_MANAGER_NAVIGATION) || ACService.accessValidation(user, Constants.AC_REPORTING)) && (
									<NavDropdown className="nav-right" title="Analysen" id="basic-nav-dropdown">
										<NavDropdown.Item href="/listGenerator">Auswertungen</NavDropdown.Item>
										<NavDropdown.Item href="/cemeteryReport">Kontaktabzug erstellen</NavDropdown.Item>
									</NavDropdown>
								)}
								<NavDropdown className="nav-right" title={user.username} id="basic-nav-dropdown">
									<NavDropdown.Item onClick={logOut}>Abmelden</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href="/password">Password Änderung</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
									<NavDropdown.Item onClick={handleOpenConsentDialog}>Consent </NavDropdown.Item>
								</NavDropdown>
								
							</div>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	}



	useEffect(() => {

		
		let lastConsentConfirmationDate = new Date(lastConsentConfirmation);
		let currentDate = new Date();
		
		
		
		if((!googleMapConsent && !requiredConsent)|| currentDate.getTime() > lastConsentConfirmationDate.getTime() || lastConsentConfirmation.length===0){
			setOpenConsentDialog(true);
		}
		

		let cookiesConsent = getCookieConsentValue("betolam-consent-cookie");
		if (cookiesConsent !== undefined && cookiesConsent === "false") {
			if (pathname !== "/consentRejected") handleConsentRejected();
		}

		// if not a hash link, scroll to top
		if (hash === '') {
			window.scrollTo(0, 0);
		}

		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView();
				}
			}, 0);
		}

		if (user !== null) {
			let decodedJwt = parseJwt(user.accessToken);
			if (decodedJwt.exp * 1000 < Date.now()) {
				logOut();
			}
		}

		if (isLoadingCementries) {
			ElasticSearchService.loadCementriesForAutoComplete(-1).then(
				response => {

					let cementries = response.data;
					let cementriesForTombstones = [...cementries];

					cementriesForTombstones.shift();

					setCementriesForPersons(cementries);
					setCementriesForTombstones(cementriesForTombstones);

					setLoadingCementries(false);
					setSuccessful(true);
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);
		}

	}, [isUserLoggedOut, isLoadingCementries, pathname, hash, key]);


	function logOut() {
		setUserLoggedOut(true);
		AuthService.logout();
	}


	return (
		<div className="global-background">

			<Container fluid="xxl" className="header-background">
				<Container fluid="xxl" className="nav-position">
					<Row>
						<Col className="col-auto me-auto"></Col>
						<Col className="col-auto">
							{getNavigation()}
						</Col>
					</Row>
				</Container>
				<div className="border-left-right">
					<Row className="logo-position">
						<Col><a href="/">
							<img src={"jfb-logo.png"} alt="Logo" />
						</a>
						</Col>
					</Row>

					<Row className="header-image-position">
						<Col className="col-auto me-auto"></Col>
						<Col className="col-auto">
							<span className="mobile-view">
								<img className="img-fluid" src={headerImage} alt="" />
							</span>
						</Col>
					</Row>

					{successful && (
						<Row className="content-layout">
							<Col>
								<Routes>
									<Route path="/" element={<Home />} />
									<Route path="login" element={<Login />} />

									<Route path="cemeteries" element={<Cementeries />} />
									<Route path="cemetery/:cementeryId" element={<Cementery onCementeryLoaded={updateHeaderImage} />} />
									<Route path="desecration" element={<Desecration />} />
									<Route path="desecration/:desecrationId" element={<Desecration />} />
									<Route path="desecrationList" element={<DesecrationList />} />

									<Route path="tombstones" element={<Tombstones />} />
									<Route path="tombstoneDetails/:cementeryId/:tombstoneId" element={<TombstoneDetails />} />
									<Route path="personDetails/:cementryId/:personId/" element={<PersonDetails />} />

									<Route path="search" element={<SearchResult />} />

									<Route path="listGenerator" element={<ListGenerator />} />

									<Route path="betolam" element={<BetOlam />} />

									<Route path="contact" element={<Contact />} />
								
								
									<Route path="impress" element={<Impressum />} />

									<Route path="faq" element={<Faq />} />

									<Route path="home" element={<Home />} />

									{user !== null && (
										<React.Fragment>



											<Route path="management/tombstone/:cementryId/:tombstoneId" element={<Tombstone />} />
											<Route path="management/tombstone/:cementryId/:tombstoneId/new" element={<Tombstone />} />

											<Route path="management/person/:cementryId/:personId" element={<Person />} />
											<Route path="management/person/:cementryId/:personId/new" element={<Person />} />

											<Route path="cemeteryDetails/:cementryId" element={<CementryDetails />} />
											<Route path="cemeteryDetails/new" element={<CementryDetails />} />
											<Route path="cemeteryOverview" element={<CementryOverview />} />

											<Route path="choices" element={<Choices />} />
											<Route path="makers" element={<MakerList />} />
											<Route path="makerDetails/:makerId" element={<MakerDetails />} />
											<Route path="makerDetails/new" element={<MakerDetails />} />

											<Route path="userDetails/:userId" element={<UserDetails />} />
											<Route path="register" element={<UserDetails />} />
											<Route path="password" element={<Password />} />
											<Route path="register" element={<UserDetails />} />
											<Route path="userManagement" element={<UserManagement />} />



											<Route path="measurementManagement" element={<MeasurementManagement />} />
											<Route path="photoUpload" element={<PhotoUpload />} />


											<Route path="reports" element={<Reports />} />
											<Route path="cemeteryReport" element={<CementryReport />} />

											<Route path="conservation-projects" element={<ConservationProjects />} />
											<Route path="conservation-projects/:cementryId/:tombstoneId" element={<ConservationProjects />} />
											<Route path="conservation/:cementryId/:tombstoneId" element={<Conservation />} />



											<Route path="footnotes" element={<Footnotes />} />
											<Route path="assessments" element={<Assessments />} />

										</React.Fragment>
									)}

									<Route path="consentRejected" element={<ConsentRejected />} />
									<Route path="dashboard" element={<Dashboard />} />
									<Route path="*" element={<Error />} />
								</Routes>
							</Col>
						</Row>
					)}

					{message && (
						<div className="form-group">
							<div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">{message}</div>
						</div>
					)}

					<div className="footer-copyright text-center py-3 footer-margin">
						<Row>
							<Col>
								<hr size="90%" />
							</Col>
						</Row>

						<Row>
							<Col>
								<img  src={'jfb-as-logo.png'} alt="Logo AS" />
							</Col>
							<Col>
								<img  src={'jfb-blfd-logo.png'} alt="Logo BLFD" />
							</Col>
							<Col>
								<img  src={'jfb-lv-logo.png'} alt="Logo LV" />
								</Col>
							<Col>
								<span className="footer-text">
									<p><strong>Bayerisches Landesamt für Denkmalpflege</strong><br />
										Schloss Seehof · 96117 Memmelsdorf
										<br />
										Tel.: 0951 4095-0 · Fax: 0951 4095-30
										<br />
										bet-olam@blfd.bayern.de</p>
								</span>
							</Col>
							<Col>
								<br />
								<p align="center">
									<a href="/faq">
										<img className="img-fluid" src={'jfb-ic-faq.png'} alt="Logo Friedhosverzeichnis" />
									</a>
									<span className="footer-text">
										<br /><strong>Antworten auf Fragen</strong>
									</span>
								</p>
							</Col>
						</Row>

						
						<Row>
							<Col>
								<span className="footer-text">
									<a href="/impress">Datenschutz | Impressum</a>
								</span>
								
							</Col>
						</Row>
						<Row>
							<Col>
								<span className="footer-text">
									<Button onClick={handleOpenConsentDialog}>Nutzungsbedingungen / Einwilligung Google Maps</Button>
								</span>
								
							</Col>
						</Row>
	
						<Row className="footer-spacer">
						</Row>
					</div>
				</div>

				<Dialog open={open} onClose={handleCloseDialog} >
					<DialogTitle>Neue Person anlegen</DialogTitle>
					<DialogContent >
						<DialogContentText>Bitte wählen Sie einen Friedhof, um eine neue Person anzulegen</DialogContentText>
						<br />
						<Autocomplete
							onChange={(event, newValue) => { handleCementryChangeDialogForPersons(newValue); }}
							id="combo-box-cementries-dialog"
							options={cementriesForPersons}
							disableClearable
							renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
						/>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleCancelDialog} variant="secondary">Abbrechen</Button>{' '}
						<Button disabled={disableForms(!selectedCemetryDialogForPersons)} onClick={handleCloseDialog} variant="secondary" href={"/management/person/" + selectedCemetryDialogForPersons + "/" + Date.now() + "/new"}>Person Anlegen</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={openTombstoneDialog} onClose={handleCloseTombstoneDialog} >
					<DialogTitle>Neuen Grabstein anlegen</DialogTitle>
					<DialogContent >
						<DialogContentText>Bitte wählen Sie einen Friedhof, um eine neuen Grabstein anzulegen</DialogContentText>
						<br />
						<Autocomplete
							onChange={(event, newValue) => { handleCementryChangeDialogForTombstones(newValue); }}
							id="combo-box-cementries-dialog"
							options={cementriesForTombstones}
							disableClearable
							renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
						/>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleCloseTombstoneDialog} variant="secondary">Abbrechen</Button>{' '}
						<Button disabled={disableForms(!selectedCemetryDialogForTombstones)} onClick={handleCloseTombstoneDialog} variant="secondary" href={"/management/tombstone/" + selectedCemetryDialogForTombstones + "/-1/new"}>Grabstein anlegen</Button>
					</DialogActions>
				</Dialog>
				
				
				<ConsentDialog onOpen={openConsentDialog} onCancel={handleCancelConsentDialog} onApproval={handleApprovalConsentDialog}/>
				
			</Container>
			
		</div>

	);
}


export default JFBApp;
