import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import { personName, checkDataExist, renderDateFromdateParts, checkArray, personIds, tombstoneNumbers } from '../../helpers/util'
import TombstoneGalery from "../../components/tombstoneGalery2"
import DetailElement from "../../components/detailElement"
import DetailElementPersonWithLink from "../../components/detailElementPersonWithLink"
import DetailElementListValues from "../../components/detailElementListValues"
import DetailElementLiteraturListValues from "../../components/detailElementLiteraturListValues"
import DetailElementZitate from "../../components/detailElementZitate"
import DetailElementStylistic from "../../components/detailElementStylistic"
import DetailElementChoices from "../../components/detailElementChoices"
import DetailElementMasse from "../../components/detailElementMasse"
import DetailElementMaterial from "../../components/detailElementMaterial"
import DetailElementInscription from "../../components/detailElementInscription"
import NavigationButton from "../../components/navigationButton"
import DetailElementChildren from "../../components/detailElementChildren"
import AuthService from "../../services/auth.service";
import FootnoteLabel from "../management/footnotes/footnoteLabel";
import ReferencesList from "../management/footnotes/referencesList";
import TombstoneMapDetailElement from "./details/tombstoneMapDetailElement2";
import ACService from "../../helpers/accessValidation.js"
import * as Constants from "../../components/constants";
import { handleStorage } from '../../helpers/jfb'


export default class TombstoneDetailsPublic extends Component {
	
	constructor(props) {
		super(props);
		
		 this.state = {
			cementries: [],
			cementery: {},
			cementryName: "",
			cementryId: "",
			tombstoneId: "",
			tombstone: {},
			tombstones: [],
			tombstoneMarker: [],
			isTombstoneLoading: true,
			isLoadingTombstones: true,
			isCementryLoading: true,
			isNeighborsLoading: true,
			currentPerson: {},
			persons: [],
			previousTombstone: "",
			nextTombstone: "",
			user:"",
			googleMapConsent:false,
			
		}
		
		this.getCementryName = this.getCementryName.bind(this);
		
	}
	

	getCementryName(cementries){
		let cementery = cementries.find(cementry => cementry.id === this.state.cementryId);	
		this.setState( {
			cementryName: cementery.name,
			cementery: cementery
		});	
	}
	
	componentDidMount() {
		
		const googleMapConsent = handleStorage("googleMapConsent");
		
		this.setState({
				googleMapConsent: googleMapConsent,
			});
		
		const user = AuthService.getCurrentUser();	
		if (user) {
			this.setState({
				user: user,
			});
		}
		
		let url = window.location.href.split('/')
		let cementryId = url[4];
		let tombstoneId = url[5];
		
		this.setState( {
			cementryId: cementryId,
			tombstoneId: tombstoneId,
		});
		

	
		
		ElasticSearchService.loadCementries().then(
			response => {	
				this.getCementryName(response.data);
				this.setState({
					cementries:  response.data,
					isCementryLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
		
			ElasticSearchService.loadTombstoneNeighbors(cementryId, tombstoneId).then(
			response => {
				let neighbors = response.data;
				this.setState({
					previousTombstone: neighbors.previous,
					nextTombstone: neighbors.next,
					isNeighborsLoading: false
				});
			
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);	
		
		ElasticSearchService.loadTombstone(cementryId, tombstoneId).then(
			response => {
				
				let data = response.data;
				let persons = data.persons;
				this.setState({
					tombstone:  data,
					persons: persons,
					currentPerson: persons[0],
					secondPerson: persons[1],
					isTombstoneLoading: false,
					tombstoneMarker: [data],

				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);		
		
		ElasticSearchService.loadTombstones(cementryId).then(
			response => {
				this.setState({
					tombstones:  response.data.tombstones,
					isLoadingTombstones: false,
				});

			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString(),
					isLoadingTombstones: false,
				});
			}
		);
		
	}
	

	
	render() {
		const {user, tombstone, tombstones, isLoadingTombstones, tombstoneMarker, currentPerson, secondPerson, isCementryLoading, isNeighborsLoading, isTombstoneLoading , cementryId, tombstoneId, cementryName, previousTombstone, nextTombstone, cementery, googleMapConsent } = this.state;		
		return (
			<Container fluid="xxl">
			<div className="text-spacer">
				<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item href={'/cemetery/'+cementryId} >{cementryName}</Breadcrumb.Item>
					  <Breadcrumb.Item href="/tombstones"> Grabsteinliste</Breadcrumb.Item>
					  <Breadcrumb.Item active> Grabstein-ID {tombstoneId}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
				 {!isCementryLoading && !isNeighborsLoading && !isTombstoneLoading && !isLoadingTombstones ? (<div>	
				<Row>
					<Col>
					</Col>
				</Row>
				<Row>
					<Col className="col-auto me-auto">
						<a href="/tombstones">zurück zur Liste</a>
					</Col>
					<Col className="col-auto">
						<NavigationButton title="Vorheriger" linkBase="tombstoneDetails" cementryId={cementryId} tombstoneId={previousTombstone} myStyle="btn badge-flat"/> 
				        <NavigationButton title="Nächster" linkBase="tombstoneDetails" cementryId={cementryId} tombstoneId={nextTombstone} myStyle="btn badge-flat"/>
					</Col>
					<Col className="col-auto">
						{(ACService.accessValidation(user, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidation(user, Constants.AC_MANAGER_NAVIGATION)) && (
							<a href={"/management/tombstone/" + cementryId + "/" + tombstoneId} className="btn badge-flat" >Grabstein bearbeiten</a>
						)}
					</Col>
				</Row>
				
				<Row>
					<Col>
						<strong>Grabstein-ID: {tombstoneId} | Personen-ID: {personIds(tombstone)} </strong><br/>
						
					</Col>
				</Row>
				<Row>
					<Col >
						<h2>{tombstone.persons.map((person, id) =>(<span key={id}>{personName(person)}<br/></span>))}</h2>
					</Col>
				</Row>
	
				<Row>
					<Col>
						<hr/>
					</Col>
				</Row>
				<Row>
					<Col>
					
			
						{checkArray(tombstone.tombstoneNumbers) ? <div>
							<strong>Weitere Grabsteinnummern:</strong><br/>
							{tombstone.tombstoneNumbers.map((entry, id) => (<span key={id}>{entry.nummer} {entry.kommentar}<br/></span>))}
						</div>: ""}
						
					</Col>
				</Row>
		
                {tombstone.bilder.length ? <TombstoneGalery photos={tombstone.bilder}/> : null}

				<Row>
					<Col>
						<hr/>
					</Col>
				</Row>

				
				{tombstone.weltkoordinaten && tombstone.weltkoordinaten.features && tombstone.weltkoordinaten.features.length>0 ? (
					
					
					
					<Row>
							<Col>
								{googleMapConsent ? (
								<TombstoneMapDetailElement cementery={cementery} tombstones={tombstones} zoom={20} tombstoneMarker={tombstoneMarker} />
							):(
								<div className="consent-info" >{Constants.consentText}<br/>
							<strong>Den Button finden Sie unten auf dieser Seite!</strong></div>
							)}
								
								
							</Col>
						</Row>
				): (
					<Row>
					<Col>
					Derzeit sind keine GEO Informationen verfügbar.
					</Col>
		  		</Row>					
				)}
				<Row>
					<Col>
						<hr/>
					</Col>
				</Row>
					
					{checkDataExist(currentPerson) ? <div>
					<DetailElement name="Eingemeißelte Grabsteinnummer" value={tombstone.carvedTombstoneNumber}/>
					<DetailElementPersonWithLink name="Name" value={currentPerson} />
					
					<DetailElement name="Geburtsdatum" value={renderDateFromdateParts(currentPerson.geburtsdatum_jahr,currentPerson.geburtsdatum_monat, currentPerson.geburtsdatum_tag)}/>                    
					<DetailElement name="Geburtsort" value={currentPerson.herkunftsort }/>
					<DetailElement name="Sterbedatum" value={renderDateFromdateParts(currentPerson.sterbedatum_jahr,currentPerson.sterbedatum_monat, currentPerson.sterbedatum_tag)}/>
					<DetailElement name="Sterbeort" value={currentPerson.sterbeort}/>
					<DetailElement name="Wohnort" value={currentPerson.wohnort}/>
					<DetailElementListValues name="Berufe" value ={currentPerson.berufe}/>
					<DetailElementListValues name="Stellung in der Gemeinde" value ={currentPerson.stellungen}/>
					
					<DetailElementPersonWithLink name="Vater" value={currentPerson.vaterPerson}/>
					<DetailElementPersonWithLink name="Mutter" value={currentPerson.mutterPerson}/>
					
					<DetailElementChildren name="Ehepartner" value={currentPerson.ehepartnerPersons}/>
					<DetailElementChildren name="Kinder" value={currentPerson.kinderPersons}/>
					</div>: ""}
					<hr/>
					{checkDataExist(secondPerson) ? <div>
					<DetailElementPersonWithLink name="Name" value={secondPerson} />
					<DetailElement name="Geburtsort" value={secondPerson.herkunftsort }/>
					<DetailElement name="Geburtsdatum" value={renderDateFromdateParts(secondPerson.geburtsdatum_jahr,secondPerson.geburtsdatum_monat, secondPerson.geburtsdatum_tag)}/>                    
					<DetailElement name="Sterbedatum" value={renderDateFromdateParts(secondPerson.sterbedatum_jahr,secondPerson.sterbedatum_monat, secondPerson.sterbedatum_tag)}/>
					<DetailElement name="Sterbeort" value={secondPerson.sterbeort}/>
					<DetailElement name="Wohnort" value={secondPerson.wohnort}/>
					<DetailElementListValues name="Berufe" value ={secondPerson.berufe}/>
					<DetailElementListValues name="Stellung in der Gemeinde" value ={secondPerson.stellungen}/>
					
					<DetailElementPersonWithLink name="Vater" value={secondPerson.vaterPerson}/>
					<DetailElementPersonWithLink name="Mutter" value={secondPerson.mutterPerson}/>
					
					<DetailElementChildren name="Ehepartner" value={secondPerson.ehepartnerPersons}/>
					<DetailElementChildren name="Kinder" value={secondPerson.kinderPersons}/>
					
					</div>: ""}
					<Row>
					<Col>
						<hr/>
					</Col>
				</Row>	
				<DetailElementInscription name="Inschriften" value= {tombstone.inschrift_hebraeisch} rights={tombstone.rights}/>
				
				<Row>
					<Col>
						<hr/>
					</Col>
				</Row>
				
					<DetailElement name="Kommentar" value={tombstone.kommentar}>
						<FootnoteLabel 
							globalReferences={tombstone.references} 
							defaultValue={tombstone.kommentar} 
						/>
					</DetailElement>
					<DetailElementStylistic name="Stilmittel" value ={tombstone.stylistics}/>
					<DetailElementZitate name="Zitate" value={tombstone.zitate}/>
					

					{checkDataExist(currentPerson) && checkDataExist(currentPerson.biografie) ? <DetailElement name="Zur Biografie" value={currentPerson.biografie}/>: null}
					{checkDataExist(secondPerson) && checkDataExist(secondPerson.biografie) ? <DetailElement name="" value={secondPerson.biografie}/>: null}
					
					
					<DetailElement name="Beschreibung des Grabsteines" value={tombstone.beschreibung}>
						<FootnoteLabel 
							globalReferences={tombstone.references} 
							defaultValue={tombstone.beschreibung} 
						/>
					</DetailElement>
					<DetailElementChoices name="Symbole, Darstellungen" value ={tombstone.symboleChoice}/>		
					{checkDataExist(tombstone.material) ? <DetailElementMaterial name="Gesteinsart" value={tombstone.material}/>: null}
					{checkDataExist(tombstone.masse) ? <DetailElementMasse name="Maße" value={tombstone.masse}/>: null}
					{checkDataExist(tombstone.herstellerMaker) ?<DetailElement name="Hersteller" value={tombstone.herstellerMaker.displayName}/>:null}
				    
					{checkDataExist(tombstone.literaturList) ? <DetailElementLiteraturListValues name="Literatur zum Grabstein" value ={tombstone.literaturList}/> : null}
					{checkDataExist(currentPerson)&&checkDataExist(currentPerson.literatur) ? <DetailElementLiteraturListValues name="Literatur zur Person" value ={currentPerson.literatur}/> : null}
					{checkDataExist(tombstone.quelle) ? <DetailElementListValues name="Quellenangaben zum Grabstein" value ={tombstone.quelle}/>: null}
					{checkDataExist(currentPerson)&& checkDataExist(currentPerson.quelle) ? <DetailElementListValues name="Quellenangaben zur Person" value ={currentPerson.quelle}/>: null}
					
				<Row>
					<Col>
						<hr/>
					</Col>
				</Row>
				{tombstone.references && tombstone.references.length>0 ? (
						<div>
						<Row >
						    <Col><strong>Einzelnachweise:</strong></Col>
						 </Row>
						 <Row>
							<Col>
								<ReferencesList references={tombstone.references}/>
							</Col>
						</Row>
						<Row>
							<Col>
								<ReferencesList references={currentPerson.references}/>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr/>
							</Col>
						</Row>
						</div>
					):""}
			
				
				<Row>
					<Col className="col-auto me-auto">
						<a href="/tombstones">zurück zur Liste</a>
					</Col>
					<Col className="col-auto">
						<NavigationButton title="Vorheriger" linkBase="tombstoneDetails" cementryId={cementryId} tombstoneId={previousTombstone} myStyle="btn badge-flat"/> 
				        <NavigationButton title="Nächster" linkBase="tombstoneDetails" cementryId={cementryId} tombstoneId={nextTombstone} myStyle="btn badge-flat"/>
					</Col>
					<Col className="col-auto">
						{(ACService.accessValidation(user, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidation(user, Constants.AC_MANAGER_NAVIGATION)) && (
							<a href={"/management/tombstone/" + cementryId + "/" + tombstoneId} className="btn badge-flat" >Grabstein bearbeiten</a>
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						
					</Col>
				</Row>
				<Row>

					
				</Row>
				</div>
				): (
          		<Row>
					<Col>Loading...</Col>
		  		</Row>
        	)}
        	</div>
			</Container>
		)
	}
}
