import React from 'react'
import { Row, Col } from "react-bootstrap";
//import Lightbox from "react-image-lightbox";
//import "react-image-lightbox/style.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

class TombstoneGalery extends React.Component {
	
	constructor(props) {
		super(props);
		
		 this.state = {
			 photoIndex: 0,
             isOpen: false,
			 images: [],
		}
	}
	
	componentDidMount() {
		let images = this.state.images
		this.props.photos.map((item, key) => 
		     images[key] = item.originalImagePath
		);
		
		this.setState({
				images: images
    	});
	}
	
  render() {
	 const { photoIndex, isOpen, images } = this.state;
	
    return (
      <Row>
      	<Col md={12}>
        {this.props.photos.map((item, key) => 
				<div key={key} className="imageGalery">
					<img key={key} width={200} style={{border: '0px', padding: '3px'}} src={item.path} alt='Grabstein' onClick={() => this.setState({ isOpen: true })}/>
					<br/>
					<span style={{fontSize: '10px'}}>{item.photographer} {' | '}{item.license?item.license:"keine Lizenz vorhanden"} <br/> Foto: {item.imageName}</span>
				</div>)}
		{isOpen && (
			<br/>
			
			
          /*<Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />*/
        )}
        </Col>
      </Row>
    )
  }
}
export default TombstoneGalery
